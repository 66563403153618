import React from 'react'
import { Link } from "react-router-dom";

// CSS Style
import "../../components/styles/user/Footer.css";

const Footer = () => {

  // Footer Section

  const handleInsta = () => {
    window.open("https://www.instagram.com/seaboss_fishmart/", "_blank");
  };

  const handleFb = () => {
    window.open(
      "https://www.facebook.com/people/Seaboss-FishMarts/61568533682829/?rdid=9FS66cvhi9UFnbSY&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2F158VtfGakT%2F",
      "_blank"
    );
  };

  const handleYoutube = () => {
    window.open("https://www.youtube.com/@SeaBossFishmarts", "_blank");
  };

  // Franchises Section

   const handleFranchises = () => {

    window.open("https://seabossfries.in/", "_blank");
  };

  const handleClickToTop = () => {
    window.scrollTo(0, 0);
  };

  // Click to open the Email and Dialer
  const handleEmail = () => {
    window.open("mailto:franchisee@seaboss.in", "_blank");
  };

  const handleDialerone = () => {
    window.open("tel:+918867906282", "_blank");
  };
  const handleDialertwo = () => {
    window.open("tel:+918867906287", "_blank");
  };


  return (
    <div>
      <section>
        <div className="footer_center">
          <div>
            <div className="footer_flex">
              <div>
                <div className="footer_title">
                  <h5 className="footer_title_content">ABOUT US</h5>
                </div>
                {/* Footer Content */}
                <div id="footer_innerPad">
                  <div>
                    <p className="footer_aboutUs">
                      SeaBoss Fishmart is a leading seafood company
                      dedicated to providing customers with the freshest
                      and highest quality fish and seafood products. With
                      a commitment to sourcing sustainably and
                      responsibly, SeaBoss Fishmart offers a diverse
                      selection of seafood sourced from trusted suppliers
                      and local fishermen.
                    </p>
                  </div>
                  <div className="footer_social_links_overall">
                    <div>
                      <button
                        onClick={handleFb}
                        className="footer_social_link"
                      >
                        <i
                          id="footer_social_link_icon"
                          className="fa-brands fa-facebook-f"
                        ></i>
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={handleInsta}
                        className="footer_social_link"
                        id="footer_social_link"
                      >
                        <i
                          id="footer_social_link_icon"
                          className="fa-brands fa-instagram"
                        ></i>
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={handleYoutube}
                        className="footer_social_link"
                        id="footer_social_link"
                      >
                        <i
                          id="footer_social_link_icon"
                          className="fa-brands fa-youtube"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <hr className="footer_seperate_mobile"></hr>
                </div>
              </div>
              <div>
                {/* Footer Quick Links */}
                <div className="footer_title">
                  <h5 className="footer_title_content">QUICK LINKS</h5>
                </div>
                <div className="footer_quick_linksDiv" id="footer_innerPad">
                  <div>
                    <Link className="footer_quick_links" to={"/"} onClick={handleClickToTop}>Home</Link>
                  </div>
                  <div>
                    <Link className="footer_quick_links" to={"/shop"} onClick={handleClickToTop}>Shop</Link>
                  </div>
                  <div>
                    <Link className="footer_quick_links" to={"/aboutus"} onClick={handleClickToTop}>About Us</Link>
                  </div>
                  <div>
                    <Link className="footer_quick_links" to={"/contact"} onClick={handleClickToTop} > Contact Us</Link>
                  </div>
                  <div>
                    <Link className="footer_quick_links" to={"/faq"} onClick={handleClickToTop} > FAQ </Link>
                  </div>
                  <div>
                    <Link className="footer_quick_links" to={"/privacy-policy"} onClick={handleClickToTop}>Privacy Policy</Link>
                  </div>
                </div>
                <div>
                  <hr className="footer_seperate_mobile"></hr>
                </div>
              </div>
              <div>
                <div className="footer_title">
                  <h5 className="footer_title_content">ADDRESS</h5>
                </div>
                <div id="footer_innerPad">
                  <div>
                    <div >
                      <div>
                        <a className="footer_addressContent" href="https://maps.app.goo.gl/6fhFdTU97V4PEUMS9"
                          target="_blank"
                          rel="noopener noreferrer">
                          NO.380/9Th, Main 23RD Cross
                          7Th Sector, HSR Layout,
                          Bangalore-560102.
                        </a>
                      </div>
                    </div>
                    <div>
                      <hr className="footer_seperate"></hr>
                    </div>
                    <div className="footer_phoneNumber_overall">
                      <div>
                        <h6 className="footer_title_content">PHONE NUMBER</h6>
                      </div>
                      <div className="footer_phone_numDiv">
                        <h6 className="footer_phone_num" onClick={handleDialerone}>+91 8867906282</h6>
                        <h6 className="footer_phone_num" onClick={handleDialertwo}>+91 8867906287</h6>
                      </div>
                    </div>
                    <div>
                      <hr className="footer_seperate"></hr>
                    </div>
                    <div className="footer_email_content">
                      <h6 className="footer_title_content">EMAIL</h6>
                      <div onClick={handleEmail}>
                        <span>franchisee@seaboss.in</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <hr className="footer_seperate_mobile"></hr>
                </div>
              </div>
              <div>
                {/* Footer Map */}
                <div className="footer_title">
                  <h5 className="footer_title_content">MAP</h5>
                </div>
                <div id="footer_innerPad" className="footer_map_container">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.0082629604753!2d77.63359788656128!3d12.907190020324972!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae155c2efbe7fd%3A0xe89890424ad16129!2sSeaboss%20Fish%20mart!5e0!3m2!1sen!2sin!4v1719053557199!5m2!1sen!2sin" className="mapStyles" allowFullScreen loading="lazy" title="seaboss_location" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
              </div>
              {/* Navigate fries page */}
              <div className="footer_franchises">
                <button className="franchise_button" onClick={handleFranchises}>FishFries Franchises Available!</button>
              </div>
            </div>

            <div className="footer_hr_line"></div>

            <div className="footer_copyright">
              <h6 className="footer_copyright_content">
                Copyright © Seaboss FishMart 2024 . ALL Right Reserved .
              </h6>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Footer;
