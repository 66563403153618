import React from 'react'
import "../../components/styles/user/WhatsappContact.css";
import { useState } from 'react';
const WhatsappContact = () => {

  // Chat Content
  const [chatContent, setChatContent] = useState("");

  const whatsappNumber = "8867906287";

  const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
    chatContent
  )}`;

  // chat submit
  const handleChatSubmit = (e) => {
    e.preventDefault();
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <section id="contactUs">
        <div className="whatsapp_main_title_div">
          <h5 className="whatsapp_main_title">Contact Us</h5>
          <h6 className="whatsapp_main_subtitle">Chat with Us!</h6>
        </div>
        <div className="whatsapp_subscribe_letter_overall">
          <div className="whatsapp_subscribe_letter_div">
            <div className="whatsapp_subscribe_letter_div_one">
              <div>
                <i
                  id="whatsapp_subscribe_email"
                  className="fa-brands fa-square-whatsapp fa-2xl"
                ></i>
              </div>
              <div className="whatsapp_subscribe_content">
                <div>
                  <h6 className="whatsapp_subscribe_title">
                    Follow Our Whatsapp Channel
                  </h6>
                </div>
                <div>
                  <p className="whatsapp_subscribe_title_para">
                    Get all the latest information on Events, Sales and
                    Offers.
                  </p>
                </div>
              </div>
            </div>
            <form
              className="whatsapp_subscribe_letter_div_two"
              onSubmit={handleChatSubmit}
            >
              <div>
                <input
                  className="whatsapp_subscribe_input"
                  type="text"
                  required
                  onChange={(e) => {
                    setChatContent(e.target.value);
                  }}
                  placeholder="Drop us a message"
                ></input>
              </div>
              <div>
                <button type="submit" className="whatsapp_subscribe_btn">
                  SEND
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  )
}

export default WhatsappContact
