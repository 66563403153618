import "../styles/App.css";
import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Home } from "../../pages/user/home";
import { Contact } from "../../pages/user/contact";
import { Faq } from "../../pages/user/Faq";
import Shop from "../../pages/user/shop";
import { PrivacyPolicy } from "../../pages/user/policyPrivacy";
// import { SearchProvider } from "../../components/user/context";
import { Franchise } from "../../pages/user/franchise";
// import { Terms } from "../../pages/user/terms";
// import {Demo} from "../../pages/user/demo";
import { AboutUs } from "../../pages/user/aboutus";
import { MainFunctionRender } from "../../components/mainRender/mainFunctionRender";
// import { UserDashboard } from "../../pages/user/dashboard/userDashboard";

function App() {
  return (
    <div>
      <BrowserRouter>
        <MainFunctionRender>
          <Routes>

            <Route path="/" element={<Home />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/faq" element={<Faq />}></Route>
            <Route path="/shop" element={<Shop />}></Route>
            <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
            <Route path="/franchise" element={<Franchise />}></Route>
            {/* <Route path="/demo" element={<Demo/>}></Route> */}
            {/* <Route path="/terms" element={<Terms />} /> */}
            <Route path="/aboutus" element={<AboutUs />}></Route>
            {/* <Route path="/userDashboard" element={<UserDashboard />}></Route> */}
          </Routes>
        </MainFunctionRender>
      </BrowserRouter>
    </div>

  );
}

export default App;
