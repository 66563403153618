import React from "react";
import { useState } from "react";

// CSS Style
import "../../styles/user/Faq.css";

//  Component import
import { HeaderNavigation } from "../../components/user/Header_Navigation";
import { ScrolltoTopSection } from "../../components/user/Scroll_to_Top_Section";
import Footer from "../../components/user/Footer";
import WhatsappContact from "../../components/user/WhatsappContact";
import NavBar from "../../components/user/NavBar";

// FontAwesome Icon import
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

export const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const Question = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  // FAQ Question and Answer
  const faqs = [
    {
      question: "What types of fish do you have available?",
      answer:
        "We offer a wide variety of fresh fish, including salmon, tilapia, cod, haddock, trout, mackerel, tuna, snapper, and many more. Availability can vary based on the season and our supplier, so it's always best to check with us for the most current selection.",
    },
    {
      question: "Are the fish fresh or frozen?",
      answer:
        "We ship to most countries worldwide. Please check our shipping policy for more details.",
    },
    {
      question: "Do you sell any other seafood besides fish?",
      answer:
        "Yes, we offer a variety of seafood, including shrimp, crab, lobster, scallops, clams, mussels, squid, and more. Like our fish selection, the availability of other seafood items can depend on the season and supplier.",
    },
    {
      question:
        "Can I request specific cuts or have fish cleaned and filleted?",
      answer:
        "Absolutely! Our skilled staff can clean, fillet, or cut the fish to your specifications. Just let us know what you need, and we will prepare it for you.",
    },
    {
      question: "What is the price range for your fish?",
      answer:
        "Prices vary depending on the type of fish, its availability, and market conditions. Typically, common fish like tilapia and catfish are more affordable, while premium options like wild-caught salmon, tuna, or lobster may be more expensive. We offer competitive pricing to ensure quality seafood is accessible to all our customers.",
    },
    {
      question:
        "Can I place a bulk order or special request for a specific event?",
      answer:
        "Yes, we accept bulk orders and can accommodate special requests for events. Please contact us in advance to discuss your needs, and we'll do our best to meet them.",
    },
    {
      question: "What are the health benefits of eating fish?",
      answer:
        "Fish is a great source of high-quality protein, omega-3 fatty acids, vitamins, and minerals. Regular consumption of fish can support heart health, brain function, and overall wellness. Different types of fish have different nutritional benefits, so it’s good to include a variety in your diet.",
    },
    {
      question: "Can you recommend a fish that's good for grilling?",
      answer:
        "Sure! Fish like salmon, tuna, swordfish, and mahi-mahi are excellent choices for grilling due to their firm texture and rich flavor. They hold up well on the grill and are easy to cook.",
    },
    {
      question: "What measures do you take to ensure food safety and quality?",
      answer:
        "We adhere to strict food safety standards, including maintaining proper temperature controls, following rigorous cleaning protocols, and regularly inspecting our products for freshness and quality. Our staff is trained to handle seafood safely to ensure you receive the best products.",
    },
  ];

  // BreadCrumbs
  const headerData = {
    headerTitle: "Frequently Asked Questions",
    // defaultPage: "Home",
    // currentPage: "Faq",
  };

  return (
    <div>
      {/* Header Section */}
      <NavBar />

      {/* BreadCrumbs */}
      <HeaderNavigation data={headerData} />

      {/* Faq Section */}
      <div className="body_content">
        <div className="faq_container">
          <h2 className="faq_title">Popular Questions</h2>
          {faqs.map((faq, index) => (
            /* FAQ question and answer body section */
            <div
              key={index}
              className={`faq_item ${activeIndex === index ? "active" : ""}`}
              onClick={() => Question(index)}
            >
              <div className="faq_question">
                {faq.question}
                <span className="faq_icon">
                  {activeIndex === index ? (
                    <FontAwesomeIcon icon={faMinus} />
                  ) : (
                    <FontAwesomeIcon icon={faPlus} />
                  )}
                </span>
              </div>
              <div
                className="faq_answer"
                style={{
                  maxHeight: activeIndex === index ? "200px" : "0",
                }}
              >
                <p>{faq.answer}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Subscribe Letter */}
        <WhatsappContact />
      </div>
      {/* Footer Section */}
      <Footer />

      {/* Scroll to Top Section */}
      <ScrolltoTopSection />
    </div>
  );
};
