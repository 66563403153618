import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { searchCategoryReducer } from "../slice/searchProductSlice";

const persistConfig = {
    key:"root",
    storage
}


const persistedReducer = persistReducer(
    persistConfig,
    combineReducers({
        searchCategory:searchCategoryReducer
    })
)

export const store = configureStore({
    reducer:persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these actions
                ignoredActions: ['persist/PERSIST', 'REGISTER'], // replace with your action type
            },
        }),
})

export const persistor = persistStore(store);