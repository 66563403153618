import { createSlice } from "@reduxjs/toolkit";

const searchSlice = createSlice({
    name:"searchOptionValue",
    initialState:{
        categorySearchValue:"",
        categorySearchValueName:"",
       // mobile view category filter
       mobileCategoryValue:""
    },
    reducers:{
        setCategorySearchValue:(state, action)=>{
            state.categorySearchValue = action.payload;
        },
        setCategorySearchValueName:(state, action)=>{
            state.categorySearchValueName = action.payload
        },
        // mobile view category filter
        setMobileCategoryValue:(state, action)=>{
            state.mobileCategoryValue = action.payload
        }
    }
})

export const { setCategorySearchValue, setCategorySearchValueName, setMobileCategoryValue} = searchSlice.actions;

export const searchCategoryReducer = searchSlice.reducer;