import React, { useState } from "react";

// CSS Style
import "../../styles/user/contactUs.css";

// Fontawesome Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

// import Componenet
import { HeaderNavigation } from "../../components/user/Header_Navigation";
import { ScrolltoTopSection } from "../../components/user/Scroll_to_Top_Section";
import WhatsappContact from "../../components/user/WhatsappContact";
import Footer from "../../components/user/Footer";
import NavBar from "../../components/user/NavBar";

export const Contact = () => {
  // Click to open Email
  const handleEmail = () => {
    window.open("mailto:franchisee@seaboss.in", "_blank");
  };

  // Click to open Dialer
  const handleDialer = () => {
    window.open("tel:+918867906287", "_blank");
  };

  // BreadCrumbs
  const headerData = {
    headerTitle: "Contact Us",
    // defaultPage: "Home",
    // currentPage: "Contact Us",
  };

  //onclick to send the details in whatsapp
  const [enquiry, setenquiry] = useState({
    userName: "",
    email: "",
    comment: "",
  });

  const whatsappNumber = "8867906287";

  const resultMessage = `Name: ${enquiry.userName}\nEmail: ${enquiry.email}\nComment: ${enquiry.comment} `;

  const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
    resultMessage
  )}`;

  const handleSubmit = (e) => {
    e.preventDefault();
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div>
      {/* Header Section */}
      <NavBar />

      {/* contact us Section */}

      {/* BreadCrumbs */}
      <HeaderNavigation data={headerData} />
      <div className="body_content">
        {/* Contact Us main */}
        <div id="contact_us">
          <section>
            <div className="contact_us_form_page">
              {/* Contact Us form */}
              <div className="contact_us_form_page_div">
                <div>
                  <form onSubmit={handleSubmit}>
                    <div className="contact_us_info">
                      <div className="contact_us_info_group">
                        <input
                          className="contact_us_info_group_input"
                          type="text"
                          placeholder="Name"
                          required
                          onChange={(event) => {
                            setenquiry({
                              ...enquiry,
                              userName: event.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="contact_us_info_group">
                        <input
                          className="contact_us_info_group_input"
                          type="email"
                          placeholder="Email"
                          required
                          onChange={(event) => {
                            setenquiry({
                              ...enquiry,
                              email: event.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="contact_us_info_group">
                        <textarea
                          className="contact_us_info_group_textarea"
                          name="comment"
                          placeholder="Comment"
                          required
                          onChange={(event) => {
                            setenquiry({
                              ...enquiry,
                              comment: event.target.value,
                            });
                          }}
                        ></textarea>
                      </div>
                      <div className="contact_us_info_button">
                        <button
                          className="contact_us_info_button_btn"
                          type="submit"
                        >
                          SEND
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* Contact Us Address */}
              <div className="contact_us_para">
                <div className="contact_us_para_heading">
                  <h6 className="contact_us_para_h6">Contact Us</h6>
                  <h3 className="contact_us_para_h3">Get in Touch</h3>
                  <p className="contact_us_paragraph">
                    Join the Seaboss Fishmart family as a franchisee and become
                    a part of our mission to deliver the freshest seafood to
                    communities. If you're interested in starting your own
                    Seaboss Fishmart or have questions about the franchise
                    process, our team is here to help. Contact us today to learn
                    more and get started!
                  </p>
                </div>
                <div className="contact_us_address">
                  <div className="contact_us_address_item">
                    <a  href="https://maps.app.goo.gl/6fhFdTU97V4PEUMS9"
                      target="_blank"
                      rel="noopener noreferrer">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      className="contact_us_address_icon"
                      />
                      </a>
                    <a
                      href="https://maps.app.goo.gl/6fhFdTU97V4PEUMS9"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="contact_us_address_item_p contact_us_item_p_address contact_us_item_email"
                    >
                      380 9th Main,22nd Cross Rd,7th Sector,Bengaluru,Karnataka
                      560102
                    </a>
                  </div>
                  <div
                    className="contact_us_address_item"
                    onClick={handleEmail}
                  >
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="contact_us_address_icon"
                    />
                    <p className="contact_us_address_item_p contact_us_item_email">
                      franchisee@seaboss.in
                    </p>
                  </div>
                  <div
                    className="contact_us_address_item"
                    onClick={handleDialer}
                  >
                    <FontAwesomeIcon
                      icon={faPhone}
                      className="contact_us_address_icon"
                    />
                    <p className="contact_us_address_item_p contact_us_item_email">
                      +91 8867906287
                    </p>
                  </div>
                </div>
                <div className="contact_us_social_media">
                  <h3 className="contact_us_social_media_head">
                    Connect on Social
                  </h3>
                  <div className="contact_us_social_icons">
                    <a
                      href="https://www.facebook.com/people/Seaboss-FishMarts/61568533682829/?rdid=9FS66cvhi9UFnbSY&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2F158VtfGakT%2F"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faFacebookF}
                        className="contact_us_social_icon"
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/seaboss_fishmart/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faInstagram}
                        className="contact_us_social_icon"
                      />
                    </a>
                    <a
                      href="https://www.youtube.com/@SeaBossFishmarts"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faYoutube}
                        className="contact_us_social_icon"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* Contact Us Map */}
            <div className="contact_us_map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.0082629604753!2d77.63359788656128!3d12.907190020324972!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae155c2efbe7fd%3A0xe89890424ad16129!2sSeaboss%20Fish%20mart!5e0!3m2!1sen!2sin!4v1719053557199!5m2!1sen!2sin"
                className="mapStyles"
                allowFullScreen
                loading="lazy"
                title="seaboss_location"
                referrerPolicy="no-referrer-when-downgrade"
                style={{ border: 0 }}
              ></iframe>
            </div>
          </section>
        </div>

        {/* Subscribe Letter */}
        <WhatsappContact />
      </div>

      {/* Footer Section */}
      <Footer />

      {/* Scroll to Top Section */}
      <ScrolltoTopSection />
    </div>
  );
};
