import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './main_folder/pages/app';
// import { UnderConstruction } from './main_folder/pages/under_construction';

import { Provider } from "react-redux";
import { persistor, store } from './features/store/store';
import { PersistGate } from 'redux-persist/integration/react';
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
      {/* <UnderConstruction/> */}
    </PersistGate>
  </Provider>

  // </React.StrictMode>   
);

reportWebVitals();
