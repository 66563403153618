import React from "react";
import { Link, useNavigate } from "react-router-dom";

// import components
import NavBar from '../../components/user/NavBar';
import { HeaderNavigation } from "../../components/user/Header_Navigation";
import WhatsappContact from "../../components/user/WhatsappContact";
import Footer from "../../components/user/Footer";
import { ScrolltoTopSection } from "../../components/user/Scroll_to_Top_Section";

// style sheet
import "../../styles/user/aboutus.css";

//all import images

// logo
import loggo from "../../assests/user/images/aboutus/loggo.png";

// gallery image
import franchise from "../../assests/user/images/aboutus/franchise_img.png";
import fishmart from "../../assests/user/images/aboutus/fishmart_img.jpg";
import fishfries from "../../assests/user/images/aboutus/fishfries_img.jpeg";

// fishmart &fries
import fries from "../../assests/user/images/aboutus/fishfries.jpeg";
import mart from "../../assests/user/images/aboutus/fishmart.jpg";

export const AboutUs = () => {

  // BreadCrumbs
  const headerData = {
    headerTitle: "About Us",
    defaultPage: "Home",
    currentPage: "About Us",
  };

  // navigate shop page
  const navigate = useNavigate();
  const shopPage = () => {
    navigate("/shop");
  };

  // navigate franchise page
  const franchisePage = () => {
    window.open("https://seabossfries.in/", "_blank");
  };
  const handleClickToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      {/* Header Section */}
      <NavBar />

      {/* BreadCrumbs */}
      <HeaderNavigation data={headerData} />

      <div className="body_content">

        {/*  Welcome to seaboss --- Main Section*/}
        <section>
          {/* welcome div */}
          <div className="aboutus_welcome_overall_div">
            <div className="aboutus_welcome_main_div">
              <div>

                {/* aboutus welcome logo */}
                <div>
                  <img className="loggo" src={loggo} alt="aboutus"></img>
                </div>

                {/* welcome heading */}
                <div>
                  <div className="welcome_heading_div">
                    <h3
                      className="welcome_heading_content"
                      id="aboutus_heading_font"
                    >
                      Welcome to Seaboss FishMart
                    </h3>
                  </div>

                  {/* welcome heading content */}
                  <div className="welcome_heading_descriptionDiv">
                    <p
                      className="welcome_heading_description"
                      id="aboutus_para_font"
                    >
                      "SeaBoss Fishmart is a leading seafood company dedicated
                      to providing customers with the freshest, most exquisite
                      seafood in town! and specialized seafood retailer that
                      offers a diverse selection of fresh and frozen fish and
                      seafood products, catering to both everyday consumers and
                      culinary enthusiasts. The mart prides itself on sourcing
                      high-quality seafood, often from local fisheries, ensuring
                      freshness and sustainability. Customers can expect a
                      variety of options, including popular fish like salmon and
                      tuna, as well as shellfish and exotic seafood.
                      Knowledgeable staff are on hand to provide expert advice
                      on selection, preparation, and cooking techniques,
                      creating an inviting shopping experience. Many fish marts,
                      including Seaboss, also engage with the community through
                      events, recipe sharing, and educational workshops on
                      sustainable practices. With a focus on quality and
                      customer satisfaction, Seaboss Fish Mart aims to be a
                      go-to destination for seafood lovers, whether they’re
                      looking for ingredients for a special meal or everyday
                      cooking needs".
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Gallery image ---Main Section  */}
        <section>
          <div className="aboutus_gallery_overall_div">
            <div className="aboutus_gallery">

              {/* Gallery franchise image */}
              <div className="gallery_franchise_overall_div">
                <div className="gallery_franchise_img_div">
                  <div className="franchise_relative">
                    <img
                      className="gallery_franchise_img"
                      src={franchise}
                      alt="img"
                    ></img>

                    {/* franchise img hover content */}
                    <div>
                      <div className="franchise_img_hover_absolute">
                        <div className="franchise_img_hover_flex">
                          <div>
                            <h3 id="aboutus_heading_font">
                              Franchise
                            </h3>
                            <p id="aboutus_para_font">
                              “Turn your passion into profit with a SeaBoss Fish
                              Mart franchise”
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Gallery fishmart image */}
              <div className="gallery_fishmart_overall_div">
                <div className="gallery_fishmart_img_div">
                  <div className="fishmart_relative">
                    <img
                      className="galley_fishmart_img"
                      src={fishmart}
                      alt="img"
                    ></img>
                    <div>
                      <div className="fishmart_img_hover_absolute">
                        <div className="fishmart_img_hover_flex">
                          <div>
                            <h3 id="aboutus_heading_font">
                              Fish Mart
                            </h3>
                            <p id="aboutus_para_font">
                              “Discover the secret to unforgettable seafood at
                              SeaBoss Fish Mart”
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* same hover copy paste  Gallery fishfries image*/}
                <div className="gallery_fishfries_img_div">
                  <div className="gallery_fishfries_relative">
                    <img
                      className="gallery_fishfries_img"
                      src={fishfries}
                      alt="img"
                    ></img>
                    <div>
                      <div className="fishfries_img_hover_absolute">
                        <div className="fishfries_img_hover_flex">
                          <div>
                            <h3 id="aboutus_heading_font">
                              Fish Fries
                            </h3>
                            <p id="aboutus_para_font">
                              “Experience the crunch and flavor that only
                              SeaBoss Fish Fries can deliver”
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Seaboss fishmart & fries---Main Section */}
        <section>
          <div className="fishmart_fries_overall_div">

            {/* seaboss fishmart & fries heading */}
            <div className="fishmart_fries_main_div">
              <div className="fishmart_fries_heading">
                <h3 id="aboutus_heading_font">
                  SEABOSS FISHMART & FRIES
                </h3>
              </div>
              <div className="fishmart_fries_heading_descriptionDiv">
                <p id="aboutus_para_font">
                  Dive into Flavor at SeaBoss Fish Fries & Fish Mart
                </p>
              </div>
            </div>

            {/* seaboss fish mart & fries image and content */}
            <div className="seaboss_overall_padding">

              {/* seaboss fishmart content */}
              <div className="seaboss_overallflex">
                <div className="seaboss_flex">
                  <div className="seaboss_img_div">
                    <img
                      className="seaboss_img"
                      src={mart}
                      alt=""
                    ></img>
                  </div>
                  <div className="seaboss_img_content">
                    <div className="seaboss_subheading">
                      <h3 id="aboutus_heading_font">Fish Mart </h3>
                    </div>
                    <div className="seaboss_subheading_content">
                      <p id="aboutus_para_font">
                        At SeaBoss Fish Mart, we’re passionate about bringing
                        the finest seafood directly from the ocean to your
                        plate. Our commitment to quality, freshness, and
                        sustainability sets us apart, ensuring that every visit
                        to our fish mart is a delightful experience.
                      </p>
                    </div>
                    <div onClick={shopPage}>
                      <Link
                        smooth="true"
                        duration={500}
                        onClick={handleClickToTop}
                      >
                        <button className="seaboss_button">
                          BUY NOW
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* seaboss fishfries content */}
              <div className="seaboss_fishfries">
                <div className="seaboss_overallflex">
                  <div className="seaboss_flex">
                    <div className="seaboss_img_content">
                      {/* <div className="seaboss_img_div none_img"> */}
                      <div
                        className="seaboss_img_div"
                        id="seaboss_fishfries_Mobile"
                      >
                        <img
                          className="seaboss_img "
                          src={fries}
                          alt=""
                        ></img>
                      </div>
                      <div className="seaboss_img_content">
                        <div className="seaboss_subheading">
                          <h3 id="aboutus_heading_font">Fish Fries </h3>
                        </div>
                        <div className="seaboss_subheading_content">
                          <p id="aboutus_para_font">
                            Our fish fries are available hot and ready to
                            eat-just stop by SeaBoss Fish Fries and grab your
                            meal to go. Perfect for a quick lunch on your busy day or a satisfying dinner at home, our meals are made with the freshest ingredients and cooked to perfection.
                          </p>
                        </div>
                        <div onClick={franchisePage}>
                          <Link
                            smooth="true"
                            duration={500}
                            onClick={handleClickToTop}
                          >
                            <button className="seaboss_button">
                              GET FRANCHISE
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/* <div className="seaboss_img_div hide_img" > */}
                    <div
                      className="seaboss_img_div"
                      id="seaboss_fishfries_Desktop"
                    >
                      <img
                        className="seaboss_img"
                        src={fries}
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Subscribe Letter */}
        <WhatsappContact />
      </div>
      {/* Footer Section */}
      <Footer />

      {/* Scroll to Top Section */}
      <ScrolltoTopSection />
    </div>
  );
};
