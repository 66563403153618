import React, { useState } from "react";

//import from react-slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//import component
import NavBar from "../../components/user/NavBar";
import WhatsappContact from "../../components/user/WhatsappContact";
import Footer from "../../components/user/Footer";
import { ScrolltoTopSection } from "../../components/user/Scroll_to_Top_Section";

//style sheet
import "../../styles/user/franchise.css";

//import franchise shop image
import franchise_shop from "../../assests/user/images/franchiseImage/franchise_shops.png";

//import staywithus background image
import bgimg from "../../assests/user/images/franchiseImage/bg_img.png";

// import benifit icon
import leadership from "../../assests/user/images/franchiseImage/leadership.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import pdf icon
import {
  faDownload,
  faEye,
  faFishFins,
  faGear,
  faChevronRight,
  faStore,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";

// import pdf
import card from "../../assests/user/images/franchisePDF/FishmartDetails.pdf";
import fishfries from "../../assests/user/images/franchisePDF/FishFriesDetails.pdf";
import fishmartproduct from "../../assests/user/images/franchisePDF/fishLists.pdf";
import fishfriesmachine from "../../assests/user/images/franchisePDF/FishFriesMachineDetails.pdf";

export const Franchise = () => {
  const [Activebutton, setActivebutton] = useState("buttonone");

  const handlepdf = (section) => {
    setActivebutton(section);
  };

  return (
    <div>
      {/* header section */}

      <NavBar />

      <div className="body_content">
        {/* Franchise shop image  main section start */}
        <section>
          <div className="franchise_shop_image_overall_content">
            <div className="franchise_shop_image_content">
              <div className="franchise_shop_imageDiv_center">
                <div className="franchise_shop_imageDiv">
                  <img
                    className="franchise_shop_image"
                    src={franchise_shop}
                    alt=""
                  ></img>
                </div>
              </div>
            </div>

            {/* Franchise Investing sub heading start*/}
            <div className="franchise_investing_overall_subcontent">
              <div>
                <h4 id="franchise_investing_heading">
                  Investing in our franchise's succes throught our hands-on
                  operational support ensures sustainable profitability and
                  growth.
                </h4>
              </div>
              <div className="franchise_investing_overallpara_div">
                <div>
                  <p id="franchise_investing_para">
                    "SeaBoss is the company where your dreams of
                    entrepreneurship meet unparalleled convenience! As a
                    franchise owner, you'll step into a world of opportunity
                    where success is within reach, and the burdens of
                    traditional business ownership are a thing of the past. At
                    Sea Boss, we've revolutionized the franchise model,
                    empowering individuals like you to invest their time and
                    resources without the hassle of day-to-day operations.
                  </p>
                </div>
                <div>
                  <p id="franchise_investing_para">
                
                    As a franchisee, you'll benefit from our proven business
                    model, comprehensive training, and ongoing support to ensure
                    your success. Join a growing community of seafood
                    enthusiasts and bring the fresh flavors of the ocean to your
                    community. With SeaBoss, you're not just starting a
                    business; you're embarking on a journey to delight customers
                    with every bite!
                  </p>
                </div>
              </div>
            </div>
            {/* Franchise Investing sub heading completed */}
          </div>
        </section>
        {/* Franchise shop image section completed */}

        {/* Stay with us start */}
        <section>
          <div className="staywithus_overall_div">
            <div className="staywithus_overall_img_div">
              <img className="staywithus_overall_background_img" src={bgimg} alt="">
              </img>
              <div className="staywithus_overall_content_center">
                <div className="staywithus_sub_content_div">
                  <div className="staywithus_sub_content_overall_div">
                    <h3 id="staywithus_heading">
                      Stay with us for long-term success
                    </h3>
                    <p id="staywithus_para">
                      At SeaBoss, we’re not just selling seafood; we’re
                      committed to delivering a superior customer experience
                      with every order. Our carefully sourced, fresh seafood is
                      prepared with passion and expertise, ensuring that every
                      dish exceeds your expectations.{" "}
                    </p>
                    <h5 id="staywithus_mini_heading">
                      “We create healthy & hygienic food and serve it with
                      unlimited happiness.”
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Stay with us End */}

        {/* Benifit section start*/}
        <section>
          <div className="benifit_overall_div">
            <div>
              <h4 id="benifit_heading">
                Why franchise with SEABOSS?
              </h4>
            </div>
            <div className="benifit_franchise_image_overall_div">
              <div className="benifit_franchise_first_overall_div">
                <div className="benifit_franchise_first_div">
                  <div
                    className="benifit_franchise_image_div"
                    id="benifit_franchise_image_div_margin"
                  >
                    <img id="benifit_franchise_image" src={leadership} alt=""></img>
                    <p id="benifit_franchise_para">50+ Varieties of Sea Food</p>
                  </div>
                  <div>
                    <div id="benifit_franchise_image_div">
                      <img id="benifit_franchise_image" src={leadership} alt=""></img>
                      <p id="benifit_franchise_para">Live Fish</p>
                    </div>
                    <div id="benifit_franchise_image_div">
                      <img id="benifit_franchise_image" src={leadership} alt=""></img>
                      <p id="benifit_franchise_para">RO Water Maintenance</p>
                    </div>
                    <div id="benifit_franchise_image_div">
                      <img id="benifit_franchise_image" src={leadership} alt=""></img>
                      <p id="benifit_franchise_para">Every Day Fresh</p>
                    </div>
                    <div id="benifit_franchise_image_div">
                      <img id="benifit_franchise_image" src={leadership} alt=""></img>
                      <p id="benifit_franchise_para">ROI- 6to8 Months</p>
                    </div>
                    <div id="benifit_franchise_image_div">
                      <img id="benifit_franchise_image" src={leadership} alt=""></img>
                      <p id="benifit_franchise_para">
                        Company Built-up Supply Chain
                      </p>
                    </div>
                    <div id="benifit_franchise_image_div">
                      <img id="benifit_franchise_image" src={leadership} alt=""></img>
                      <p id="benifit_franchise_para">
                        Unsold Stock Re-Collection
                      </p>
                    </div>
                    <div id="benifit_franchise_image_div">
                      <img id="benifit_franchise_image" src={leadership} alt=""></img>
                      <p id="benifit_franchise_para">Market Competitive Price</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="benifit_franchise_second_overall_div">
                <div className="benifit_franchise_second_div">
                  <div className="benifit_franchise_image_div"  id="benifit_franchise_image_div_margin">
                    <img id="benifit_franchise_image" src={leadership} alt=""></img>
                    <p id="benifit_franchise_para">Fully Tax Free</p>
                  </div>
                  <div id="benifit_franchise_image_div">
                    <img id="benifit_franchise_image" src={leadership} alt=""></img>
                    <p id="benifit_franchise_para">Free Promotions</p>
                  </div>
                  <div id="benifit_franchise_image_div">
                    <img id="benifit_franchise_image" src={leadership} alt=""></img>
                    <p id="benifit_franchise_para">
                      Man Power Training & Support
                    </p>
                  </div>
                  <div id="benifit_franchise_image_div">
                    <img id="benifit_franchise_image" src={leadership} alt=""></img>
                    <p id="benifit_franchise_para">
                      Dedicated Applications
                    </p>
                  </div>
                  <div id="benifit_franchise_image_div">
                    <img id="benifit_franchise_image" src={leadership} alt=""></img>
                    <p id="benifit_franchise_para">Online Delivery</p>
                  </div>
                  <div id="benifit_franchise_image_div">
                    <img id="benifit_franchise_image" src={leadership} alt=""></img>
                    <p id="benifit_franchise_para">
                      Get Monthly Profit of 1.5L
                    </p>
                  </div>
                  <div id="benifit_franchise_image_div">
                    <img id="benifit_franchise_image" src={leadership} alt=""></img>
                    <p id="benifit_franchise_para">
                      75% Refundable On Investment
                    </p>
                  </div>
                  <div id="benifit_franchise_image_div">
                    <img id="benifit_franchise_image" src={leadership} alt=""></img>
                    <p id="benifit_franchise_para">Free Transportation</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Benifit section end*/}

        {/*  Why Partner With Us ---Main Section------*/}
        {/* -----Pdf  start--- */}
        <section className="pdf_section">
          <div className="pdf_overall_div">
            <div>
              <div>
                {/* Pdf heading */}
                <h4 id="pdf_heading">Why Partner With Us ?</h4>
              </div>
              <div className="pdf_overall_flex" id="pdf_section">
                <div>
                  <div
                    className={
                      Activebutton === "buttonone"
                        ? "pdf_img_flexhover"
                        : "pdf_img_flexcenter"
                    }
                    onClick={() => handlepdf("buttonone")}
                  >
                    <div className="pdf_image_div">
                      <FontAwesomeIcon className={Activebutton === "buttonone" ? "pdf_image" : "pdf_image_active"} icon={faStore} />
                    </div>
                  </div>
                  <div
                    className={
                      Activebutton === "buttontwo"
                        ? "pdf_img_flexhover"
                        : "pdf_img_flexcenter"
                    }
                    onClick={() => handlepdf("buttontwo")}
                  >
                    <div className="pdf_image_div">
                      <FontAwesomeIcon className={Activebutton === "buttontwo" ? "pdf_image" : "pdf_image_active"} icon={faUtensils} />
                    </div>
                  </div>
                  <div
                    className={
                      Activebutton === "buttonthree"
                        ? "pdf_img_flexhover"
                        : "pdf_img_flexcenter"
                    }
                    onClick={() => handlepdf("buttonthree")}
                  >
                    <div className="pdf_image_div">
                      <FontAwesomeIcon className={Activebutton === "buttonthree" ? "pdf_image" : "pdf_image_active"} icon={faFishFins} />

                    </div>
                  </div>
                  <div
                    className={
                      Activebutton === "buttonfour"
                        ? "pdf_img_flexhover"
                        : "pdf_img_flexcenter"
                    }
                    onClick={() => handlepdf("buttonfour")}
                  >
                    <div className="pdf_image_div">
                      <FontAwesomeIcon className={Activebutton === "buttonfour" ? "pdf_image" : "pdf_image_active"} icon={faGear}></FontAwesomeIcon>
                    </div>
                  </div>
                </div>

                <div className="pdf_image_heading_para">
                  {Activebutton === "buttonone" ? <PdfSectionOne /> : ""}
                  {Activebutton === "buttontwo" ? <PdfSectionTwo /> : ""}
                  {Activebutton === "buttonthree" ? <PdfSectionThree /> : ""}
                  {Activebutton === "buttonfour" ? <PdfSectionFour /> : ""}
                </div>
              </div>
              {/* Carousel section start */}
              <div>
                <PdfSectionCarousel />
              </div>
              {/* Carousel section completed */}
            </div>
          </div>
        </section>
        {/*  --------Pdf  End-------- */}

        {/* whatsapp Component */}
        <WhatsappContact />
      </div>

      {/* Footer Section */}
      <Footer />

      {/* Scroll to Top Section */}
      <ScrolltoTopSection />
    </div>
  );
};

// Pdf Section Start

export const PdfSectionOne = () => {
  return (
    <div className="pdf_section_content_Overall">
      <div className="pdfsection_iconandcontent_padding">
        <div>
          <h4 id="pdfsection_heading">Join the Fishmart Franchise Network!</h4>
        </div>
        <div className="pdfsection_iconandcontent_overall_div">
          <div className="pdfsection_iconandcontent_flex">
            <div className="pdfsection_padding_correction">
              <FontAwesomeIcon id="pdfsection_arrowicon" icon={faChevronRight} />
            </div>
            <div>
              <p id="pdfsection_para">
                Fishmart offers a
                <span className="pdfsection_para_highlight"> unique franchise model</span> that combines quality,
                sustainability, and community focus.
              </p>
            </div>
          </div>
          <div className="pdfsection_iconandcontent_flex">
            <div className="pdfsection_padding_correction">
              <FontAwesomeIcon id="pdfsection_arrowicon" icon={faChevronRight} />
            </div>
            <div>
              <p id="pdfsection_para">
                <span className="pdfsection_para_highlight">Fishmart is a trusted name in seafood.</span> Benefit from our
                reputation as you build your business.
              </p>
            </div>
          </div>
          <div className="pdfsection_iconandcontent_flex">
            <div className="pdfsection_padding_correction" >
              <FontAwesomeIcon id="pdfsection_arrowicon" icon={faChevronRight} />
            </div>
            <div>
              <p id="pdfsection_para">
                Our franchisees receive thorough training in operations,
                marketing, and <span className="pdfsection_para_highlight"> customer service.</span>
              </p>
            </div>
          </div>
          <div className="pdfsection_iconandcontent_flex">
            <div className="pdfsection_padding_correction"  >
              <FontAwesomeIcon id="pdfsection_arrowicon" icon={faChevronRight} />
            </div>
            <div>
              <p id="pdfsection_para">
                Fishmart franchisee, you’ll have the opportunity to engage with
                your local community through  tastings,about <span className="pdfsection_para_highlight"> seafood sustainability.</span>
              </p>
            </div>
          </div>

          <div className="pdfsection_iconandcontent_flex">
            <div className="pdfsection_padding_correction" >
              <FontAwesomeIcon id="pdfsection_arrowicon" icon={faChevronRight} />
            </div>
            <div>
              <p id="pdfsection_para">
                New franchisees benefit greatly from the <span className="pdfsection_para_highlight">  mentorship</span> of seasoned
                members.
              </p>
            </div>
          </div>

        </div>

        <div className="pdfcarousel_button_flex">
          <div className="pdfsection_button_div">
            <a
              className="pdfsection_button_a"
              href={card}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="pdfsection_button">
                <FontAwesomeIcon
                  className="pdfsection_button_fontawsome_icon"
                  icon={faEye}
                />
                View Details
              </button>
            </a>
          </div>
          <div className="pdfsection_button_div">
            <a className="pdfsection_button_a" href={card} download="Franchise_Details">
              <button className="pdfsection_button">
                <FontAwesomeIcon
                  className="pdfsection_button_fontawsome_icon"
                  icon={faDownload}
                />
                Download PDF
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PdfSectionTwo = () => {
  return (
    <div className="pdf_section_content_Overall">
      <div className="pdfsection_iconandcontent_padding">
        <div>
          <h4 id="pdfsection_heading">Fish Fries</h4>
        </div>
        <div className="pdfsection_iconandcontent_overall_div">
          <div className="pdfsection_iconandcontent_flex">
            <div className="pdfsection_padding_correction" >
              <FontAwesomeIcon id="pdfsection_arrowicon" icon={faChevronRight} />
            </div>
            <div>
              <p id="pdfsection_para">
                A fish fry is a <span className="pdfsection_para_highlight"> social gathering </span> where fish is fried and served,
                often accompanied by various sides and condiments.
              </p>
            </div>
          </div>
          <div className="pdfsection_iconandcontent_flex">
            <div className="pdfsection_padding_correction" >
              <FontAwesomeIcon id="pdfsection_arrowicon" icon={faChevronRight} />
            </div>
            <div>
              <p id="pdfsection_para">
                It’s a  <span className="pdfsection_para_highlight"> popular tradition</span> in many cultures, especially in coastal
                areas and during specific times of the year, like Lent.
              </p>
            </div>
          </div>
          <div className="pdfsection_iconandcontent_flex">
            <div className="pdfsection_padding_correction"  >
              <FontAwesomeIcon id="pdfsection_arrowicon" icon={faChevronRight} />
            </div>
            <div>
              <p id="pdfsection_para">
                Secret Recipe Batter Our <span className="pdfsection_para_highlight"> unique batter blend </span> combines the
                perfect spices and seasonings, giving our fish fries
              </p>
            </div>
          </div>
          <div className="pdfsection_iconandcontent_flex">
            <div className="pdfsection_padding_correction" >
              <FontAwesomeIcon id="pdfsection_arrowicon" icon={faChevronRight} />
            </div>
            <div>
              <p id="pdfsection_para">
                Perfect Pairings Enjoy our fish fries with a side of <span className="pdfsection_para_highlight"> hand-cut
                  fries</span> ,coleslaw, or our house-made tartar sauce
              </p>
            </div>
          </div>
          <div className="pdfsection_iconandcontent_flex">
            <div className="pdfsection_padding_correction" >
              <FontAwesomeIcon id="pdfsection_arrowicon" icon={faChevronRight} />
            </div>
            <div>
              <p id="pdfsection_para">
                Classic <span className="pdfsection_para_highlight"> Golden Fry
                </span> Our original recipe that started it all,
                featuring flaky cod fried to golden perfection.
              </p>
            </div>
          </div>
        </div>

        <div className="pdfcarousel_button_flex">
          <div className="pdfsection_button_div">
            <a
              className="pdfsection_button_a"
              href={fishfries}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="pdfsection_button">
                <FontAwesomeIcon
                  className="pdfsection_button_fontawsome_icon"
                  icon={faEye}
                />
                View Details
              </button>
            </a>
          </div>
          <div className="pdfsection_button_div">
            <a className="pdfsection_button_a" href={fishfries} download="Fish_Fries_Menu_Card">
              <button className="pdfsection_button">
                <FontAwesomeIcon
                  className="pdfsection_button_fontawsome_icon"
                  icon={faDownload}
                />
                Download PDF
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PdfSectionThree = () => {
  return (
    <div className="pdf_section_content_Overall">
      <div className="pdfsection_iconandcontent_padding">
        <div>
          <h4 id="pdfsection_heading">FishMart Product</h4>
        </div>
        <div className="pdfsection_iconandcontent_overall_div">
          <div className="pdfsection_iconandcontent_flex">
            <div className="pdfsection_padding_correction" >
              <FontAwesomeIcon id="pdfsection_arrowicon" icon={faChevronRight} />
            </div>
            <div>
              <p id="pdfsection_para">
                Fishmart, we take pride in offering the  <span className="pdfsection_para_highlight"> freshest,
                  highest-quality seafood products</span> straight from the ocean to your
                table
              </p>
            </div>
          </div>

          <div className="pdfsection_iconandcontent_flex">
            <div className="pdfsection_padding_correction" >
              <FontAwesomeIcon id="pdfsection_arrowicon" icon={faChevronRight} />
            </div>
            <div>
              <p id="pdfsection_para">
                <span className="pdfsection_para_highlight"> Fresh Fish Fillets</span> from a variety of fish fillets, including
                salmon, cod, tilapia, and more. Each fillet is expertly cleaned
                and ready for your favorite recipes.
              </p>
            </div>
          </div>
          <div className="pdfsection_iconandcontent_flex">
            <div className="pdfsection_padding_correction" >
              <FontAwesomeIcon id="pdfsection_arrowicon" icon={faChevronRight} />
            </div>
            <div>
              <p id="pdfsection_para">
                Wild-Caught Shrimp Our wild-caught shrimp are peeled, deveined,
                and <span className="pdfsection_para_highlight"> packed with flavor.</span>
              </p>
            </div>
          </div>
          <div className="pdfsection_iconandcontent_flex">
            <div className="pdfsection_padding_correction" >
              <FontAwesomeIcon id="pdfsection_arrowicon" icon={faChevronRight} />
            </div>
            <div>
              <p id="pdfsection_para">
                Sustainable Shellfish Enjoy our <span className="pdfsection_para_highlight"> selection of mussels, clams, and
                  oysters </span> sourced from sustainable fisheries.
              </p>
            </div>
          </div>
          <div className="pdfsection_iconandcontent_flex">
            <div className="pdfsection_padding_correction" >
              <FontAwesomeIcon id="pdfsection_arrowicon" icon={faChevronRight} />
            </div>
            <div>
              <p id="pdfsection_para">
                <span className="pdfsection_para_highlight"> Fish Marinades & Rubs </span> Crafted to complement the natural flavors
                of fish and shellfish.
              </p>
            </div>
          </div>
        </div>

        <div className="pdfcarousel_button_flex">
          <div className="pdfsection_button_div">
            <a
              className="pdfsection_button_a"
              href={fishmartproduct}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="pdfsection_button">
                <FontAwesomeIcon
                  className="pdfsection_button_fontawsome_icon"
                  icon={faEye}
                />
                View Details
              </button>
            </a>
          </div>
          <div className="pdfsection_button_div">
            <a
              className="pdfsection_button_a"
              href={fishmartproduct}
              download="Fishmart_Product"
            >
              <button className="pdfsection_button">
                <FontAwesomeIcon
                  className="pdfsection_button_fontawsome_icon"
                  icon={faDownload}
                />
                Download PDF
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PdfSectionFour = () => {
  return (
    <div className="pdf_section_content_Overall">
      <div className="pdfsection_iconandcontent_padding">
        <div>
          <h4 id="pdfsection_heading">Fish Fry Machines</h4>
        </div>
        <div className="pdfsection_iconandcontent_overall_div">
          <div className="pdfsection_iconandcontent_flex">
            <div className="pdfsection_padding_correction">
              <FontAwesomeIcon id="pdfsection_arrowicon" icon={faChevronRight} />
            </div>
            <div >
              <p id="pdfsection_para">
                Fish fry machines are specialized equipment designed for <span className="pdfsection_para_highlight"> frying
                  fish efficiently and safely.</span>
              </p>
            </div>
          </div>
          <div className="pdfsection_iconandcontent_flex">
            <div className="pdfsection_padding_correction">
              <FontAwesomeIcon id="pdfsection_arrowicon" icon={faChevronRight} />
            </div>
            <div >
              <p id="pdfsection_para">
                They come in various types, catering to  <span className="pdfsection_para_highlight"> different scales of
                  cooking</span>-from home kitchens to large commercial settings.
              </p>
            </div>
          </div>
          <div className="pdfsection_iconandcontent_flex">
            <div className="pdfsection_padding_correction">
              <FontAwesomeIcon id="pdfsection_arrowicon" icon={faChevronRight} />
            </div>
            <div>
              <p id="pdfsection_para">
                <span className="pdfsection_para_highlight"> Versatile Cooking Options </span> From classic fish fillets to crispy
                calamari, our machine can handle it all.
              </p>
            </div>
          </div>
          <div className="pdfsection_iconandcontent_flex">
            <div className="pdfsection_padding_correction">
              <FontAwesomeIcon id="pdfsection_arrowicon" icon={faChevronRight} />
            </div>
            <div>
              <p id="pdfsection_para">
                <span className="pdfsection_para_highlight"> Deep Fryers </span> Commonly used in homes and restaurants, these
                machines immerse food in hot oil for even cooking.
              </p>
            </div>
          </div>
          <div className="pdfsection_iconandcontent_flex">
            <div className="pdfsection_padding_correction" >
              <FontAwesomeIcon id="pdfsection_arrowicon" icon={faChevronRight} />
            </div>
            <div>
              <p id="pdfsection_para">
                <span className="pdfsection_para_highlight">Quick Clean-Up </span> The non-stick, removable frying basket and oil
                filtration system make post-cooking clean-up a breeze.
              </p>
            </div>
          </div>
        </div>

        <div className="pdfcarousel_button_flex">
          <div className="pdfsection_button_div">
            <a
              className="pdfsection_button_a"
              href={fishfriesmachine}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="pdfsection_button">
                <FontAwesomeIcon
                  className="pdfsection_button_fontawsome_icon"
                  icon={faEye}
                />
                View Details
              </button>
            </a>
          </div>
          <div className="pdfsection_button_div">
            <a
              className="pdfsection_button_a"
              href={fishfriesmachine}
              download="Fries_Machine_Details"
            >
              <button className="pdfsection_button">
                <FontAwesomeIcon
                  className="pdfsection_button_fontawsome_icon"
                  icon={faDownload}
                />
                Download PDF
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
// Pdf Section End

// Carousel Section start
export const PdfSectionCarousel = () => {
  var pdfCarousel = {
    dots: true,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="slider-container" id="slider_container_pages">
      <Slider {...pdfCarousel}>
        <div>
          {/* -------Pdf Carousel Slider Start------- */}
          <div className="pdfcarousel_overall_slider">
            <div className="pdfcarousel_overall_slider_div">
              <div id="pdfcarousel_image_div">
                <div className="pdfcarousel_image_flex_div">
                  <FontAwesomeIcon className="pdfcarousel_image" icon={faStore} />
                </div>
              </div>
              <div>
                <h4 id="pdfcarousel_slider_heading">
                  Join Fishmart Franchise!
                </h4>
              </div>
              <div id="pdfcarousel_icon_content_overall_flex">
                <div >
                  <div>
                    {/*pdfsection_para used */}
                    <p id="pdfsection_para">
                      Fishmart offers a       <span className="pdfsection_para_highlight"> unique franchise model</span> that combines
                      quality, sustainability, and community focus.
                    </p>
                  </div>
                  
                </div>
                
                <div>
                  <div>
                    {/*pdfsection_para used */}
                    <p id="pdfsection_para">
                      <span className="pdfsection_para_highlight">Fishmart is a trusted name in seafood.</span> Benefit from our
                      reputation as you build your business.
                    </p>
                  </div>
                </div>
                <div>
                  <div>
                    {/*pdfsection_para used */}
                    <p id="pdfsection_para">
                      Our franchisees receive thorough training in operations,
                      marketing, and <span className="pdfsection_para_highlight"> customer service.</span>
                    </p>
                  </div>
                </div>
                <div >
                  <div>
                    {/*pdfsection_para used */}
                    <p id="pdfsection_para">
                      Fishmart franchisee, you’ll have the opportunity to engage
                      with your local community through tastings,
                      about <span className="pdfsection_para_highlight"> seafood sustainability.</span>
                    </p>
                  </div>
                </div>
                <div>
                  <div>
                    {/*pdfsection_para used */}
                    <p id="pdfsection_para">
                    New franchisees benefit greatly from the <span className="pdfsection_para_highlight">mentorship</span> of seasoned members, receiving personalized guidance and support as they navigate the startup process.
                    </p>
                  </div>
                </div>
              </div>
              <div id="pdfcarousel_button_flex">
                <div id="pdfcarousel_button_div">
                  {/* pdfsection_button_a used */}
                  <a className="pdfsection_button_a" href={card} download="Franchise_Details">
                    <button id="pdfcarousel_button">
                      {/* pdfsection_button_fontawsome_icon used */}
                      <FontAwesomeIcon
                        className="pdfsection_button_fontawsome_icon"
                        icon={faDownload}
                      />
                      Download PDF
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pdfcarousel_overall_slider_div">
          <div id="pdfcarousel_image_div">
            <div className="pdfcarousel_image_flex_div">
              <FontAwesomeIcon className="pdfcarousel_image" icon={faUtensils} />
            </div>
          </div>
          <div>
            <h4 id="pdfcarousel_slider_heading">Fish Fries</h4>
          </div>
          <div id="pdfcarousel_icon_content_overall_flex">
            <div >
              <div>
                {/*pdfsection_para used */}
                <p id="pdfsection_para">
                  A fish fry is a <span className="pdfsection_para_highlight"> social gathering </span>  where fish is fried and
                  served, often accompanied by various sides and condiments.
                </p>
              </div>
            </div>
            <div >
              <div>
                {/*pdfsection_para used */}
                <p id="pdfsection_para">
                  It’s a <span className="pdfsection_para_highlight"> popular tradition</span> in many cultures, especially in
                  coastal areas and during specific times of the year, like
                  Lent.
                </p>
              </div>
            </div>
            <div >
              <div>
                {/*pdfsection_para used */}
                <p id="pdfsection_para">
                  Secret Recipe Batter Our <span className="pdfsection_para_highlight"> unique batter blend </span> combines the
                  perfect spices and seasonings, giving our fish fries.
                </p>
              </div>
            </div>
            <div>
              <div>
                {/*pdfsection_para used */}
                <p id="pdfsection_para">
                  Perfect Pairings Enjoy our fish fries with a side of <span className="pdfsection_para_highlight"> hand-cut
                    fries</span>, coleslaw, or our house-made tartar sauce.
                </p>
              </div>
            </div>
            <div>
              <div>
                {/*pdfsection_para used */}
                <p id="pdfsection_para">
                  Classic <span className="pdfsection_para_highlight"> Golden Fry
                  </span> Our original recipe that started it all,
                  featuring flaky cod fried to golden perfection.
                </p>
              </div>
            </div>
          </div>
          <div id="pdfcarousel_button_flex">
            <div id="pdfcarousel_button_div">
              {/* pdfsection_button_a used */}
              <a className="pdfsection_button_a" href={fishfries} download="Fish_Fries_Menu_Card">
                <button id="pdfcarousel_button">
                  {/* pdfsection_button_fontawsome_icon used */}
                  <FontAwesomeIcon
                    className="pdfsection_button_fontawsome_icon"
                    icon={faDownload}
                  />
                  Download PDF
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className="pdfcarousel_overall_slider_div">
          <div id="pdfcarousel_image_div">
            <div className="pdfcarousel_image_flex_div">
              <FontAwesomeIcon className="pdfcarousel_image" icon={faFishFins} />
            </div>
          </div>
          <div>
            <h4 id="pdfcarousel_slider_heading">FishMart Product</h4>
          </div>
          <div id="pdfcarousel_icon_content_overall_flex">
            <div >
              <div>
                {/*pdfsection_para used */}
                <p id="pdfsection_para">
                  Fishmart, we take pride in offering the <span className="pdfsection_para_highlight"> freshest,
                    highest-quality seafood products</span>straight from the ocean to
                  your table.
                </p>
              </div>
            </div>
            <div >
              <div>
                {/*pdfsection_para used */}
                <p id="pdfsection_para">
                  <span className="pdfsection_para_highlight"> Fresh Fish Fillets</span>
                  from a variety of fish fillets, including
                  salmon, cod, tilapia, and more. Each fillet is expertly
                  cleaned and ready for your favorite recipes.
                </p>
              </div>
            </div>
            <div>
              <div>
                {/*pdfsection_para used */}
                <p id="pdfsection_para">
                  Wild-Caught Shrimp Our wild-caught shrimp areeeled,
                  deveined, and <span className="pdfsection_para_highlight"> packed with flavor.</span>
                </p>
              </div>
            </div>
            <div >
              <div>
                {/*pdfsection_para used */}
                <p id="pdfsection_para">
                  Sustainable Shellfish Enjoy our  <span className="pdfsection_para_highlight"> selection of mussels, clams, and
                    oysters </span> sourced from sustainable fisheries.
                </p>
              </div>
            </div>
            <div>
              <div>
                {/*pdfsection_para used */}
                <p id="pdfsection_para">
                  <span className="pdfsection_para_highlight"> Fish Marinades & Rubs </span> Crafted to complement the natural
                  flavors of fish and shellfish.
                </p>
              </div>
            </div>
          </div>
          <div id="pdfcarousel_button_flex">
            <div id="pdfcarousel_button_div">
              {/* pdfsection_button_a used */}
              <a
                className="pdfsection_button_a"
                href={fishmartproduct}
                download="Fishmart_Product"
              >
                <button id="pdfcarousel_button">
                  {/* pdfsection_button_fontawsome_icon used */}
                  <FontAwesomeIcon
                    className="pdfsection_button_fontawsome_icon"
                    icon={faDownload}
                  />
                  Download PDF
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="pdfcarousel_overall_slider_div">
          <div id="pdfcarousel_image_div">
            <div className="pdfcarousel_image_flex_div">
              <FontAwesomeIcon className="pdfcarousel_image" icon={faGear}></FontAwesomeIcon>
            </div>
          </div>
          <div>
            <h4 id="pdfcarousel_slider_heading">Fish Fry Machines</h4>
          </div>
          <div id="pdfcarousel_icon_content_overall_flex">
            <div>
              <div>
                {/*pdfsection_para used */}
                <p id="pdfsection_para">
                  Fish fry machines are specialized equipment designed for
                  <span className="pdfsection_para_highlight"> frying
                    fish efficiently and safely.</span>
                </p>
              </div>
            </div>
            <div>
              <div>
                {/*pdfsection_para used */}
                <p id="pdfsection_para">
                  They come in various types, catering to    <span className="pdfsection_para_highlight"> different scales of
                    cooking</span>-from home kitchens to large commercial settings.
                </p>
              </div>
            </div>
            <div>
              <div>
                {/*pdfsection_para used */}
                <p id="pdfsection_para">
                  <span className="pdfsection_para_highlight"> Versatile Cooking Options </span>
                  From classic fish fillets to crispy
                  calamari, our machine can handle it all.
                </p>
              </div>
            </div>
            <div>
              <div>
                {/*pdfsection_para used */}
                <p id="pdfsection_para">
                  <span className="pdfsection_para_highlight"> Deep Fryers </span>
                  Commonly used in homes and restaurants, these
                  machines immerse food in hot oil for even cooking.
                </p>
              </div>
            </div>
            <div>
              <div>
                {/*pdfsection_para used */}
                <p id="pdfsection_para">
                  <span className="pdfsection_para_highlight">Quick Clean-Up </span> The non-stick, removable frying basket and oil
                  filtration system make post-cooking clean-up a breeze.
                </p>
              </div>
            </div>
          </div>
          <div id="pdfcarousel_button_flex">
            <div id="pdfcarousel_button_div">
              {/* pdfsection_button_a used */}
              <a
                className="pdfsection_button_a"
                href={fishfriesmachine}
                download="Fries_Machine_Details"
              >
                <button id="pdfcarousel_button">
                  {/* pdfsection_button_fontawsome_icon used */}
                  <FontAwesomeIcon
                    className="pdfsection_button_fontawsome_icon"
                    icon={faDownload}
                  />
                  Download PDF
                </button>
              </a>
            </div>
          </div>
        </div>
        {/* -------Pdf Carousel Slider End------- */}
      </Slider>
    </div>
  );
};
// Carousel Section End