import React, { useEffect, useState } from "react";
import "../styles/user/products.css";
import { Link } from "react-router-dom";

// SeaFish
import anchovy from "../../assests/user/images/seaFish/anchovy.png";
import bream from "../../assests/user/images/seaFish/Bream.jpg";
import croaker from "../../assests/user/images/seaFish/croaker.png";
import salmon from "../../assests/user/images/seaFish/salmon.jpg";
import mackerel from "../../assests/user/images/seaFish/mackerel.jpg";
import blackPomfret from "../../assests/user/images/seaFish/blackPomfret.jpg";
import herring from "../../assests/user/images/seaFish/herring.jpg";
import ribbon from "../../assests/user/images/seaFish/ribbon.jpg";
import tuna from "../../assests/user/images/seaFish/tuna.jpg";
import milk from "../../assests/user/images/seaFish/milk.png";
import barracuda from "../../assests/user/images/seaFish/barracuda.jpg";
import bombayduck from "../../assests/user/images/seaFish/bombayduck.jpeg";
import dart from "../../assests/user/images/seaFish/dart.jpeg";
import emperor from "../../assests/user/images/seaFish/emperor.png";
import goat from "../../assests/user/images/seaFish/goat.jpg";
import grouper from "../../assests/user/images/seaFish/grouper.png";
import grunter from "../../assests/user/images/seaFish/grunter.jpg";
import haddock from "../../assests/user/images/seaFish/haddock.jpg";
import hathead from "../../assests/user/images/seaFish/hathead.jpeg";
import hilsa from "../../assests/user/images/seaFish/hilsa.jpg";
import jew from "../../assests/user/images/seaFish/jew.jpg";
import lady from "../../assests/user/images/seaFish/lady.jpg";
import moon from "../../assests/user/images/seaFish/moon.jpeg";
import mullet from "../../assests/user/images/seaFish/mullet.jpg";
import perch from "../../assests/user/images/seaFish/perch.jpeg";
import pearlspot from "../../assests/user/images/seaFish/pearlspot.jpg";
import porgy from "../../assests/user/images/seaFish/porgy.jpg";
import ray from "../../assests/user/images/seaFish/ray.jpg";
import redsnapper from "../../assests/user/images/seaFish/redsnapper.jpeg";
import sardine from "../../assests/user/images/seaFish/sardine.jpg";
import scad from "../../assests/user/images/seaFish/scad.jpg";
import seer from "../../assests/user/images/seaFish/seer.jpg";
import shark from "../../assests/user/images/seaFish/shark.jpg";
import silver from "../../assests/user/images/seaFish/silver.jpg";
import surgeon from "../../assests/user/images/seaFish/surgeon.png";
import sword from "../../assests/user/images/seaFish/sword.jpg"; 
import tonguesole from "../../assests/user/images/seaFish/tonguesole.jpg";
import travelly from "../../assests/user/images/seaFish/travelly.jpg";
import whitepomfret from "../../assests/user/images/seaFish/whitepomfret.jpg";
import yellowscad from "../../assests/user/images/seaFish/yellowscad.jpg";
import seabass from "../../assests/user/images/seaFish/seabass.jpg";

// Fresh Water Fish
import catla from "../../assests/user/images/freshWaterFish/catla.jpg";
import barramundi from "../../assests/user/images/freshWaterFish/barramundi.jpg";
import rohu from "../../assests/user/images/freshWaterFish/rohu.png";
import tilapia from "../../assests/user/images/freshWaterFish/tilapia.png";
import pilot from "../../assests/user/images/freshWaterFish/pilot.png";
import eel from "../../assests/user/images/freshWaterFish/eel.jpg";
import whiteCarpFish from "../../assests/user/images/freshWaterFish/whiteCarpFish.png";
import catFish from "../../assests/user/images/freshWaterFish/catFish.jpg";
import calms from "../../assests/user/images/freshWaterFish/calms.jpg";
import codfish from "../../assests/user/images/freshWaterFish/codfish.png";
import murrelfish from "../../assests/user/images/freshWaterFish/murrelfish.png";
import goldenfish from "../../assests/user/images/freshWaterFish/golden_fish.png";
import riverfish from "../../assests/user/images/freshWaterFish/riverfish.jpg";

// Prawns fish
import atlantic from "../../assests/user/images/prawns/atlantic.jpg";
import blackTigerShrimp from "../../assests/user/images/prawns/blackTigerShrimp.jpg";
import northernPrawn from "../../assests/user/images/prawns/northernPrawn.jpg";
import whiteLegShrimp from "../../assests/user/images/prawns/whiteLegShrimp.jpg";
import giantTigerShrimp from "../../assests/user/images/prawns/giantTigerShrimp.jpg";
import spotPrawn from "../../assests/user/images/prawns/spotPrawn.jpg";

// Crab
import dungness from "../../assests/user/images/crab/dungness.png";
import mud from "../../assests/user/images/crab/mud.jpg";
import blueCrab from "../../assests/user/images/crab/blueCrab.jpg";
import seaCrab from "../../assests/user/images/crab/seaCrab.jpg";
import stoneCrab from "../../assests/user/images/crab/stoneCrab.png";

// Squid
import arrow from "../../assests/user/images/squid/arrow.jpg";
import european from "../../assests/user/images/squid/european.jpg";
import giant from "../../assests/user/images/squid/giant.png";
import japaneseFlying from "../../assests/user/images/squid/japaneseFlyingSquid.jpg";
import jumbo from "../../assests/user/images/squid/jumbo.jpg";

// Pickles & Masala Items
import fishPickle from "../../assests/user/images/pickled_Masala/fishPickles.png";
import fishKebabMasala from "../../assests/user/images/pickled_Masala/fishKebabMasala.jpeg";
import fishKulambu from "../../assests/user/images/pickled_Masala/fishKulambuMasala.jpeg";
import fish_65 from "../../assests/user/images/pickled_Masala/Fish_65_Masala.jpg";
import prawnpickle from "../../assests/user/images/pickled_Masala/prawnpickles.jpg";

// Dried Fish
import driedAnchovy from "../../assests/user/images/driedFish/driedAnchovy.jpg";
import driedShankara from "../../assests/user/images/driedFish/driedShankaraFish.png";
import driedPrawn from "../../assests/user/images/driedFish/DriedPrawn.png";
import driedShell from "../../assests/user/images/driedFish/Driedshell.png";
import driedCroker from "../../assests/user/images/driedFish/driedCroakerFish.jpg";
import driedMackrelFish from "../../assests/user/images/driedFish/DriedMackrelFish.png";
import driedBombayDuckFish from "../../assests/user/images/driedFish/driedBombayDuckFish.png";
import driedShrimp from "../../assests/user/images/driedFish/driedShrimp.jpg";
import driedEel from "../../assests/user/images/driedFish/driedEelFish.png";
import driedSardine from "../../assests/user/images/driedFish/driedSardine.png";
import driedRibbon from "../../assests/user/images/driedFish/driedRibbon.png";
import driedPorgy from "../../assests/user/images/driedFish/driedPorgy.jpg";

// React-slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// FontAwesome icon import
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

export const Products = ({ propsProductValue }) => {
  
  // whatsapp subscription
  const whatsappNumber = "8867906287";

  const whatsappUrl = `https://wa.me/${whatsappNumber}`;

  const handleChatSubmit = (e) => {
    e.preventDefault();
    window.open(whatsappUrl, "_blank");
  };

  const kilo = " / kg";

  const gram = "- (50 g)";

  const gram100 = "- (100 g)";

  const productList = {
    seaFish: [
      {
        categoryName: "seaFish",
        discount: "-34%",
        featured: false,
        img: anchovy,
        stock: "In Stock",
        productName: "Anchovy fish",
        mainAmount: 250.0,
        subAmount: 350.0,
        type: kilo,
        para: "Anchovies are small, green ﬁsh with blue reﬂections due to a silver-colored longitudinal stripe that runs from the base of the caudal (tail) ﬁn.They range from 2 to 40 centimeters(1 to 15+1⁄2 inches) in adult length, and their more slender ﬁsh in northern populations.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: herring,
        stock: "In Stock",
        productName: "Herring fish",
        mainAmount: 200.0,
        subAmount: 250.0,
        type: kilo,
        para: "Herring have a blue-green upper body with silvery sides and are devoid of markings.The body is laterally compressed and the scales along the underside project in a slightly serrated arrangement.Scales are large and easily removed.Their tails are deeply forked and they lack adipose ﬁns.",
      },
      {
        categoryName: "seaFish",
        discount: "-14%",
        featured: true,
        img: croaker,
        stock: "In Stock",
        productName: "Croaker fish",
        mainAmount: 150.0,
        subAmount: 200.0,
        type: kilo,
        para: "The Atlantic croaker is a member of the drum and croaker family. It has a slightly elongated, fusiform body with a rounded dorsal proﬁle and nearly straight ventral proﬁle. It has a moderately large and sub terminal mouth, and there are between six and ten tiny chin barbells.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: salmon,
        stock: "In Stock",
        productName: "Salmon fish",
        mainAmount: 400.0,
        subAmount: 600.0,
        type: kilo,
        para: "They're found in tributaries of the Paciﬁc and Atlantic Oceans, and most species are anadromous: They are born in streams and rivers, migrate out to the open sea, and then return to freshwater again to reproduce. Salmons have color throughout their lifetimes.",
      },
      {
        categoryName: "seaFish",
        discount: "-33%",
        featured: false,
        img: mackerel,
        stock: "In Stock",
        productName: "Mackerel fish",
        mainAmount: 150.0,
        subAmount: 250.0,
        type: kilo,
        para: "Mackerels are rounded and torpedo-shaped, with a slender, keeled tail base, a forked tail,and a row of small inlets behind the dorsal and anal ﬁns. They are carnivorous ﬁshes and feed on plankton, crustaceans, mollusks, ﬁsh eggs, and small ﬁsh.",
      },
      {
        categoryName: "seaFish",
        discount: "-14%",
        featured: true,
        img: blackPomfret,
        stock: "In Stock",
        productName: "Black Pomfret Fish",
        mainAmount: 600.0,
        subAmount: 800.0,
        type: kilo,
        para: "The black pomfret has a compressed deep body with dorsal and ventral proﬁles being equally convex.This species has small dark grey scales which cover the entirety of the body.The fork measurement of Black Pomfret is commonly 30 cm but can also range up to 75 cm in length.",
      },
      {
        categoryName: "seaFish",
        discount: "-50%",
        featured: false,
        img: bream,
        stock: "In Stock",
        productName: "Bream fish",
        mainAmount: 130.0,
        subAmount: 200.0,
        type: kilo,
        para: "The bream is a medium-sized ﬁsh and a member of the carp family, so displays the typical carp shape. It is a bottom-feeding ﬁsh that preys on worms, snails and pea mussels. It lives in large ponds, lakes and slow-moving rivers where it congregates in quite large shoals.",
      },
      {
        categoryName: "seaFish",
        discount: "-50%",
        featured: false,
        img: ribbon,
        stock: "In Stock",
        productName: "Ribbon fish",
        mainAmount: 150.0,
        subAmount: 200.0,
        type: kilo,
        para: "A ribbon-shaped ﬁsh, very thin from side to side, the oarﬁsh may grow to a length of about 9 meters (30.5 feet) and a weight of 300 kg (660 pounds).It is shiny silver in color, with long, red, oarlike rises as a manelike crest on top of the head.",
      },
      {
        categoryName: "seaFish",
        discount: "-14%",
        featured: true,
        img: tuna,
        stock: "In Stock",
        productName: "Tuna fish",
        mainAmount: 100.0,
        subAmount: 200.0,
        type: kilo,
        para: "Tuna are remarkable and impressive wild animals. The Atlantic blueﬁn can reach ten feet in length and weigh as much as 2,000 pounds (more than a horse).Their specialized body shape, ﬁns, and scales enable some species of tuna to swim as fast as 43 miles per hour.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: milk,
        stock: "In Stock",
        productName: "Milk fish",
        mainAmount: 200.0,
        subAmount: 300.0,
        type: kilo,
        para: "Milkﬁsh have a spindle-like shape that is moderately compressed, smooth and streamlined. Its body color is silvery on the belly and sides, grading to olive green or blue on the back. The wild catch is limited to just slightly more than one percent of total world production.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: barracuda,
        stock: "In Stock",
        productName: "Barracuda fish",
        mainAmount: 200.0,
        subAmount: 270.0,
        type: kilo,
        para: "Barracudas are known for their sleek, elongated bodies and impressive speed, making them skilled predators in the ocean. They have sharp teeth and a voracious appetite, primarily feeding on smaller fish and sometimes squid.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: bombayduck,
        stock: "In Stock",
        productName: "Bombay duck fish",
        mainAmount: 100.0,
        subAmount: 150.0,
        type: kilo,
        para: "Bombay duck, also known as Bombil (scientific name: Harpadon nehereus), is a fish found primarily in the waters of the Arabian Sea and the Bay of Bengal. Despite its name, it's not a duck but rather a type of lizardfish.Bombay duck is typically found in shallow waters and is often caught near the sea floor. It prefers sandy or muddy substrates.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: dart,
        stock: "In Stock",
        productName: "Dart fish",
        mainAmount: 500.0,
        subAmount: 700.0,
        type: kilo,
        para: "Dartfish, belonging to the family Callionymidae, are small, colorful fish commonly found in tropical and subtropical waters, often inhabiting sandy or muddy substrates near coral reefs.Dartfish prefer shallow waters and are often seen hovering above the substrate, where they feed on small crustaceans and plankton.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: emperor,
        stock: "In Stock",
        productName: "Emperor fish",
        mainAmount: 150.0,
        subAmount: 200.0,
        type: kilo,
        para: "Emperor fish, primarily belonging to the family Lethrinidae, are popular in both commercial and recreational fishing.  Emperor fish have a robust body with a slightly compressed shape, large mouths, and distinctive coloring. They often feature a mix of blue, yellow, and gray hues, with horizontal stripes.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: goat,
        stock: "In Stock",
        productName: "Goat fish",
        mainAmount: 200.0,
        subAmount: 250.0,
        type: kilo,
        para: "Goatfish, belonging to the family Mullidae, are known for their distinctive appearance and behavior.Goatfish have elongated bodies with two distinct barbels on their chins, which they use to probe the sand for food. They often have colorful markings, including stripes or spots.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: grouper,
        stock: "In Stock",
        productName: "Grouper fish",
        mainAmount: 200.0,
        subAmount: 250.0,
        type: kilo,
        para: "Grouper fish, belonging to the family Epinephelidae, are a diverse group of fish found primarily in warmer waters around the worldGroupers are characterized by their stout bodies, large mouths, and strong jaws. They often have vibrant colors and patterns that can vary significantly between species.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: grunter,
        stock: "In Stock",
        productName: "Grunter fish",
        mainAmount: 300.0,
        subAmount: 450.0,
        type: kilo,
        para: "Grunter fish, belonging to the family Pomadasyidae, are known for their distinctive grunting sounds, which they produce using their swim bladders.Grunter fish typically have elongated bodies and flattened heads. Their coloration can vary, but many have a silver or grayish hue with dark stripes or spots.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: haddock,
        stock: "In Stock",
        productName: "Haddock fish",
        mainAmount: 170.0,
        subAmount: 200.0,
        type: kilo,
        para: "Haddock (Melanogrammus aeglefinus) is a popular fish known for its mild flavor and flaky white flesh.Haddock is primarily found in the North Atlantic Ocean, often inhabiting deeper waters, typically on the continental shelf. They prefer sandy or muddy bottomsHaddock are carnivorous, feeding on a variety of prey, including small fish, crustaceans, and mollusks.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: hathead,
        stock: "In Stock",
        productName: "Hathead fish",
        mainAmount: 150.0,
        subAmount: 250.0,
        type: kilo,
        para: "Hathead fish, also known as the hatchetfish or Mola mola, refer to several species, but typically the term refers to the species in the family Mola. Hathead fish are notable for their unique, flattened bodies and large, rounded heads. They have a distinct shape that resembles a giant floating disc.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: hilsa,
        stock: "In Stock",
        productName: "Hilsa fish",
        mainAmount: 1500.0,
        subAmount: 2500.0,
        type: kilo,
        para: "Hilsa fish, known scientifically as Tenualosa ilisha, is a highly prized species in South Asia, particularly in India, Bangladesh, and Pakistan.Hilsa has a distinctive silver body with a bluish or greenish back and a deeply forked tail.Hilsa is primarily found in the Bay of Bengal and the rivers that flow into it, such as the Ganges and Brahmaputra. .",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: jew,
        stock: "In Stock",
        productName: "Jew fish",
        mainAmount: 150.0,
        subAmount: 250.0,
        type: kilo,
        para:"Jewfish, commonly known as the goliath grouper (Epinephelus itajara), is a large fish found in tropical and subtropical waters. Jewfish have a robust, elongated body with a broad head and large mouth.Jewfish are carnivorous, feeding on a variety of prey, including fish, crustaceans, and octopuses. They are powerful predators and can consume large prey." 
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: lady,
        stock: "In Stock",
        productName: "Lady fish",
        mainAmount: 200.0,
        subAmount: 300.0,
        type: kilo,
        para: "Ladyfish, scientifically known as Elops saurus, is a species found primarily in coastal waters and estuaries. Ladyfish have elongated, sleek bodies with a silvery appearance and a somewhat forked tail.Ladyfish are carnivorous, primarily feeding on small fish and invertebrates. They are known for their active and aggressive feeding behavior.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: moon,
        stock: "In Stock",
        productName: "Moon fish",
        mainAmount: 100.0,
        subAmount: 150.0,
        type: kilo,
        para: "Moonfish, often referred to as opah (Lampris guttatus), is a unique species known for its distinctive appearance and behavior.   Moonfish have a round, flat body that resembles a disc. They have vibrant coloration, typically featuring a combination of silvery blue, red, and orange hues. Their large pectoral fins give them a unique look.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: mullet,
        stock: "In Stock",
        productName: "Mullet fish",
        mainAmount: 250.0,
        subAmount: 300.0,
        type: kilo,
        para: "Mullet fish, belonging to the family Mugilidae, are a group of fish known for their robust bodies and distinctive behaviors.Mullet have elongated, cylindrical bodies with a bluish or greenish back and silver sides. They typically have a forked tail and can grow up to 30 inches (76 cm) long, depending on the species.mullet are commonly found in coastal waters, estuaries, and rivers. ",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: perch,
        stock: "In Stock",
        productName: "Perch fish",
        mainAmount: 200.0,
        subAmount: 250.0,
        type: kilo,
        para: "Perch fish, particularly those from the family Percidae, are popular freshwater fish known for their distinctive appearance and behavior.Perch have elongated bodies with a slightly flattened shape. They typically feature vertical stripes on their sides, with the coloration varying from greenish to yellow. They have spiny dorsal fins that add to their distinctive look.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: pearlspot,
        stock: "In Stock",
        productName: "Pearl spot fish",
        mainAmount: 140.0,
        subAmount: 200.0,
        type: kilo,
        para: "Pearl spot fish, commonly known as Etroplus suratensis, is a popular fish species, particularly in South Asia. The pearl spot fish has a distinctive oval body, with a greenish or bluish hue and several prominent dark spots resembling pearls along its sides.Pearl spot fish are omnivorous, feeding on a variety of food sources, including algae, detritus, small insects, and zooplankton.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: porgy,
        stock: "In Stock",
        productName: "Porgy fish",
        mainAmount: 200.0,
        subAmount: 300.0,
        type: kilo,
        para: "Porgy fish, belonging to the family Sparidae, are a group of fish known for their robust bodies and strong, flat teeth. Porgies are commonly found in warm coastal waters, usually inhabiting sandy or rocky bottoms near reefs. They can be found in both shallow and deeper waters.Porgies typically have a deep, laterally compressed body with a blunt snout and large eyes. ",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: ray,
        stock: "In Stock",
        productName: "Ray fish",
        mainAmount: 120.0,
        subAmount: 200.0,
        type: kilo,
        para: "Ray fish, commonly referred to simply as Rays, belong to the order Myliobatiformes and include a diverse group of species such as stingrays, manta rays, and skates.Rays have flattened bodies with large pectoral fins that extend from their bodies, giving them a disc-like shape. They often have long tails, which can be equipped with stingers in species like stingrays. ",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: redsnapper,
        stock: "In Stock",
        productName: "Red snapper fish",
        mainAmount: 200.0,
        subAmount: 300.0,
        type: kilo,
        para: "Red snapper, commonly known as Lutjanus campechanus, is a highly valued fish found in warmer waters. Red snapper has a distinctive reddish-pink coloration, with a slightly elongated body and a pointed snout.Red snapper is highly regarded for its firm, flaky flesh and mild flavor, making it a popular choice in many cuisines. It can be grilled, baked, or fried.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: sardine,
        stock: "In Stock",
        productName: "Sardines fish",
        mainAmount: 100.0,
        subAmount: 150.0,
        type: kilo,
        para: "Sardines are small, schooling fish belonging to the family Clupeidae. They are known for their rich flavor and high nutritional value. Sardines have a streamlined body with a silvery sheen, which helps them evade predatorsSardines inhabit coastal and offshore waters, often forming large schools in nutrient-rich areas. They prefer temperate and subtropical oceans.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: scad,
        stock: "In Stock",
        productName: "Scad fish",
        mainAmount: 300.0,
        subAmount: 500.0,
        type: kilo,
        para: "Scad fish typically refers to various species in the family Carangidae, commonly found in tropical and subtropical waters. Scad fish are usually found in schools near the surface of the ocean, often around reefs and inshore areas.Scad are sought after for both commercial and recreational fishing. They are also popular as bait fish for larger game fish.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: seer,
        stock: "In Stock",
        productName: "Seer fish",
        mainAmount: 400.0,
        subAmount: 600.0,
        type: kilo,
        para: "Seer fish, commonly known as Scomberomorus commerson, is a popular species in tropical and subtropical waters, often prized for its flavor and culinary value. grading to olive green or blue on the back. The wild catch is limited to just slightly more than one percent of total world production.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: shark,
        stock: "In Stock",
        productName: "Shark fish",
        mainAmount: 200.0,
        subAmount: 250.0,
        type: kilo,
        para: "Sharks have fascinating adaptations, such as multiple rows of teeth, a keen sense of smell, and the ability to detect electrical fields in the water. Sharks are often portrayed as dangerous predators, but many species pose little to no threat to humans. Educating people about their role in the ecosystem can help change perceptions.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: silver,
        stock: "In Stock",
        productName: "Silver fish",
        mainAmount: 150.0,
        subAmount: 200.0,
        type: kilo,
        para: " Silverfish (Lepisma saccharinum) are small, wingless insects known for their silvery, metallic appearance and fish-like movements.Silverfish feed on starches and sugars, often consuming paper, glue, and even dead insects. They can damage books, wallpaper, and other household items. Silverfish can live for several years, making them persistent pests if not managed.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: surgeon,
        stock: "In Stock",
        productName: "Surgeon fish",
        mainAmount: 100.0,
        subAmount: 200.0,
        type: kilo,
        para: "Surgeonfish belong to the family Acanthuridae and are also known as tangs or palette fish.Surgeonfish are commonly found in coral reefs and warm, shallow waters of the Indo-Pacific and the Caribbean. Surgeonfish often form schools and exhibit interesting social behaviors. They can be territorial and may engage in displays to establish dominance.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: sword,
        stock: "In Stock",
        productName: "Sword fish",
        mainAmount: 200.0,
        subAmount: 250.0,
        type: kilo,
        para: "Swordfish are large, powerful fish known for their elongated, flattened bills resembling swords. Swordfish are found in warm and temperate waters around the world, often in the Atlantic, Indian, and Pacific Oceans.TSwordfish primarily feed on squid, fish, and crustaceans. Their sharp bill is used to slash at prey, making it easier to catch.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: tonguesole,
        stock: "In Stock",
        productName: "Tongue sole fish",
        mainAmount: 200.0,
        subAmount: 300.0,
        type: kilo,
        para: "Tongue soles are flatfish, meaning they have a flattened body that allows them to lie on the sea floor. They have a unique shape with both eyes on one side of their body.Tongue sole fish are commercially fished and are considered a delicacy in many cuisines, particularly in Asian and European dishes.Tongue sole fish spawn in warmer months. They release eggs into the water column, where fertilization occurs externally.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: travelly,
        stock: "In Stock",
        productName: "Travelly fish",
        mainAmount: 200.0,
        subAmount: 250.0,
        type: kilo,
        para: "Trevally fish are found in tropical and subtropical waters around the world, primarily in the Indian and Pacific Oceans. Trevallies are predatory fish that feed on smaller fish, squid, and crustaceans. Trevally typically spawn in deeper waters, with females releasing eggs into the water column for fertilization by males.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: whitepomfret,
        stock: "In Stock",
        productName: "White pomfret fish",
        mainAmount: 600.0,
        subAmount: 800.0,
        type: kilo,
        para: "White pomfret is a flat, oval-shaped fish with a silver-white coloration and a slightly compressed body. It has a smooth texture and can grow up to 3 feet long.White pomfret is primarily found in the warm waters of the Indian Ocean and the western Pacific Ocean.White pomfret primarily feeds on small fish, crustaceans, and other marine organisms.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: yellowscad,
        stock: "In Stock",
        productName: "Yellow scad fish",
        mainAmount: 250.0,
        subAmount: 350.0,
        type: kilo,
        para: "Yellow scad are small to medium-sized fish with a streamlined body and distinctive yellow or golden stripes along their sides. They are often recognized by their shiny, silvery appearance.Yellow scad primarily feed on zooplankton, small fish, and crustaceans.Yellow scad generally spawn in open waters, releasing eggs into the water column where fertilization occurs externally.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: seabass,
        stock: "In Stock",
        productName: "Sea bass fish",
        mainAmount: 300.0,
        subAmount: 450.0,
        type: kilo,
        para: "Sea bass have elongated bodies, a large mouth, and a streamlined shape, making them powerful swimmers.  Sea bass are predatory fish that primarily feed on smaller fish, crustaceans, and mollusks.Sea bass typically spawn in warmer months. For example, striped bass migrate to freshwater rivers to spawn.",
      },
    ],
    freshWater: [
      {
        categoryName: "freshWater",
        discount: "-34%",
        featured: false,
        img: barramundi,
        stock: "In Stock",
        productName: "Barramundi fish",
        mainAmount: 250.0,
        subAmount: 350.0,
        type: kilo,
        para: "Barramundi can be recognized by a distinct pointed head, concave forehead, large jaw extending behind the eye and a rounded tail ﬁn.They have a dorsal ﬁn with seven or eight strong spines and a second dorsal in often or eleven rays.",
      },
      {
        categoryName: "freshWater",
        discount: "-50%",
        featured: false,
        img: catla,
        stock: "In Stock",
        productName: "Catla fish",
        mainAmount: 100.0,
        subAmount: 150.0,
        type: kilo,
        para: "Catla is a ﬁsh with large and broad head, a large protruding lower jaw, and upturned mouth. It has large, greyish scales on its dorsal side and whitish on its belly. It reaches up to 182 cm (6.0 ft) in length and 38.6 kg (85 lb) in weight.",
      },
      {
        categoryName: "freshWater",
        discount: "-14%",
        featured: true,
        img: rohu,
        stock: "In Stock",
        productName: "Rohu fish",
        mainAmount: 100.0,
        subAmount: 150.0,
        type: kilo,
        para: "The rohu is a large, silver-colored ﬁsh of typical cyprinid shape, with a conspicuously arched head. Adults can reach a maximum weight of 45 kg (99 lb) and maximum length of 2 m (6.6 ft), but average around 1⁄2 m (1.6 ft).",
      },
      {
        categoryName: "freshWater",
        discount: "-25%",
        featured: false,
        img: tilapia,
        stock: "In Stock",
        productName: "Thilapia fish",
        mainAmount: 100.0,
        subAmount: 150.0,
        type: kilo,
        para: "Thilapia are a hardy, fast growing ﬁsh, that can live up to ten years and reach ten pounds in weight. Thilapia are shaped like a sunﬁsh or crappie and are easily identiﬁable by the interrupted lateral line characteristic of the Chiclid family of ﬁshes.",
      },
      {
        categoryName: "freshWater",
        discount: "-14%",
        featured: true,
        img: pilot,
        stock: "In Stock",
        productName: "Pilot fish",
        mainAmount: 80.0,
        subAmount: 100.0,
        type: kilo,
        para: "The pilot ﬁsh is elongated and has a forked tail, a lengthwise keel on each side of the tail base, and a low ﬁrst dorsal ﬁn. It grows to a length of about 60 cm (2 feet) but is usually about 35 cm long. It is distinctively marked with ﬁve to seven vertical, dark bands on a bluish body.",
      },
      {
        categoryName: "freshWater",
        discount: "-14%",
        featured: true,
        img: eel,
        stock: "In Stock",
        productName: "Eel fish",
        mainAmount: 150.0,
        subAmount: 250.0,
        type: kilo,
        para: "An eel is distinguished externally from most other ﬁshes by its elongated body, which is seldom laterally compressed. A continuous dorsal, anal, and caudal ﬁn runs around the tail tip pelvic ﬁns are always absent; and gill openings are usually reduced.",
      },
      {
        categoryName: "freshWater",
        discount: "-14%",
        featured: true,
        img: whiteCarpFish,
        stock: "In Stock",
        productName: "White Carp fish",
        mainAmount: 100.0,
        subAmount: 150.0,
        type: kilo,
        para: "The mrigal carp (Cirrhinus cirrhosus), also known as the white carp, is a species of ray-ﬁnned ﬁsh in the carp family. Native to streams and rivers in India, the only surviving wild population is in the Cauvery River, leading to its IUCN rating as vulnerable.",
      },
      {
        categoryName: "freshWater",
        discount: "-14%",
        featured: true,
        img: catFish,
        stock: "In Stock",
        productName: "Cat fish",
        mainAmount: 100.0,
        subAmount: 150.0,
        type: kilo,
        para: "Catﬁsh are so-named because of their whisker- like barbels, which are located on the nose, each side of the mouth, and on the chin. Most catﬁsh possess leading spines in their dorsal and pectoral ﬁns. Catﬁsh are scaleless, a characteristic of from most other teleost ﬁsh.",
      },
      {
        categoryName: "freshWater",
        discount: "-14%",
        featured: true,
        img: calms,
        stock: "In Stock",
        productName: "Clams",
        mainAmount: 250.0,
        subAmount: 350.0,
        type: kilo,
        para: " Clams are filter feeders, meaning they extract food particles from the water. They primarily consume phytoplankton, bacteria, and organic debris. Clam larvae develop into juvenile clams and eventually settle into their adult habitats, where they grow to maturity.Clams are a staple in various cuisines around the world, enjoyed for their sweet and briny flavor.",
      },
      {
        categoryName: "freshWater",
        discount: "-14%",
        featured: true,
        img: codfish,
        stock: "In Stock",
        productName: "Cod fish",
        mainAmount: 100.0,
        subAmount: 150.0,
        type: kilo,
        para: "Cod fish are characterized by their elongated bodies, large heads, and three dorsal fins.  Cod are predatory fish that primarily feed on smaller fish, crustaceans, and mollusks.Cod typically spawn in colder months, releasing eggs into the water where fertilization occurs externally. Cod is highly valued for its mild flavor and flaky texture, making it a favorite in many cuisines.",
      },
      {
        categoryName: "freshWater",
        discount: "-14%",
        featured: true,
        img: murrelfish,
        stock: "In Stock",
        productName: "Murrel fish",
        mainAmount: 300.0,
        subAmount: 500.0,
        type: kilo,
        para: " Murrel fish are an important species for local fisheries, contributing to the livelihoods of many fishing communities. Increasing interest in farming murrel fish sustainably is seen as a way to meet growing demand without overfishing wild populations.Murrel fish typically spawn during the monsoon season, laying eggs in shallow waters among vegetation.",
      },
      {
        categoryName: "freshWater",
        discount: "-14%",
        featured: true,
        img: goldenfish,
        stock: "In Stock",
        productName: "Golden fish",
        mainAmount: 150.0,
        subAmount: 250.0,
        type: kilo,
        para: "Goldfish are known for their bright orange-gold color, round bodies, and long, flowing fins.Goldfish are omnivores and enjoy a varied diet, including fish flakes, pellets, vegetables, and live or frozen foods like brine shrimp.Golden orfe primarily feed on insects, small invertebrates, and plant material in their natural habitats.",
      },
      {
        categoryName: "freshWater",
        discount: "-14%",
        featured: true,
        img: riverfish,
        stock: "In Stock",
        productName: "River fish",
        mainAmount: 150.0,
        subAmount: 250.0,
        type: kilo,
        para: "River eels have long, snake-like bodies with smooth, slimy skin and a dorsal fin that runs the length of their body. River eels are opportunistic feeders, primarily consuming small fish, crustaceans, and insects. River eels are catadromous, meaning they migrate from freshwater to the ocean to spawn, typically in the Sargasso Sea.",
      },
    ],
    prawn: [
      {
        categoryName: "prawn",
        discount: "-34%",
        featured: false,
        img: atlantic,
        stock: "In Stock",
        productName: "Atlantic prawn",
        mainAmount: 300.0,
        subAmount: 400.0,
        type: kilo,
        para: "The Atlantic white shrimp was the first commercially important shrimp in the United States. The prawn species' body is bluish-white in color with slightly pink sides and has tail flippers that are black near the base with green and yellow margins.",
      },
      {
        categoryName: "prawn",
        discount: "-50%",
        featured: false,
        img: blackTigerShrimp,
        stock: "In Stock",
        productName: "Black Tiger Shrimp",
        mainAmount: 350.0,
        subAmount: 450.0,
        type: kilo,
        para: "The Black Tiger Shrimp's natural range is vast, from the east coast of Africa, throughout the Indian Ocean, and in South Asia. Black Tiger Shrimp are named for their large size and striped tails and can reach a length of over a foot in some cases.",
      },
      {
        categoryName: "prawn",
        discount: "-14%",
        featured: true,
        img: northernPrawn,
        stock: "In Stock",
        productName: "Northern Prawn",
        mainAmount: 200.0,
        subAmount: 250.0,
        type: kilo,
        para: "The Northern prawn is a hermaphrodite and starts its life as a male after 4-5 years it turns female. The species is found mostly in areas with soft, muddy sediment where temperatures range from 1-6°C at a depth between 150 and 600 meters.",
      },
      {
        categoryName: "prawn",
        discount: "-25%",
        featured: false,
        img: whiteLegShrimp,
        stock: "In Stock",
        productName: "White Leg Shrimp",
        mainAmount: 150.0,
        subAmount: 250.0,
        type: kilo,
        para: "L. vannamei grows to a maximum length of 230 mm (9.1 in),with a carapace length of 90 mm (3.5 in). Adults live in the ocean, at depths to 72 m (236 ft), while juveniles live in estuaries.The rostrum is moderately long, with 7–10 teeth on the dorsal side and two to four teeth on the ventral side.",
      },
      {
        categoryName: "prawn",
        discount: "-33%",
        featured: false,
        img: giantTigerShrimp,
        stock: "In Stock",
        productName: "Giant Tiger Shrimp",
        mainAmount: 400.0,
        subAmount: 500.0,
        type: kilo,
        para: "Females can reach about 33 cm (13 in) long, but are typically 25–30 cm (10–12 in) long and weigh 200–320 g (7–11 oz); males are slightly smaller at 20–25 cm (8–10 in) long and weighing 100–170 g (3.5–6.0 oz). The carapace and abdomen are transversely banded with alternative red and white.",
      },
      {
        categoryName: "prawn",
        discount: "-33%",
        featured: false,
        img: spotPrawn,
        stock: "In Stock",
        productName: "Spot prawn",
        mainAmount: 300.0,
        subAmount: 350.0,
        type: kilo,
        para: "Spot prawns are large shrimp known for their sweet, tender meat. Spot prawns are opportunistic feeders, consuming a varied diet that includes phytoplankton, zooplankton, small fish, and detritus.They use their long antennae to detect food in their environment, foraging primarily at night. Spot prawns typically spawn in late winter to early spring. Females carry fertilized eggs under their tails until they hatch.",
      },
    ],
    crab: [
      {
        categoryName: "crab",
        discount: "-14%",
        featured: true,
        img: dungness,
        stock: "In Stock",
        productName: "Dungness Crab",
        mainAmount: 100.0,
        subAmount: 150.0,
        type: kilo,
        para: "The Dungeness crab is a decapod, related to shrimp, lobster, and other crab. It has a broad, oval body covered by a hard chitinous shell, four pairs of walking legs and a pair of claws.",
      },
      {
        categoryName: "crab",
        discount: "-14%",
        featured: true,
        img: mud,
        stock: "In Stock",
        productName: "Mud Crab",
        mainAmount: 120.0,
        subAmount: 170.0,
        type: kilo,
        para: "Mud Crab are a member of the family Portunidae. They are large crabs with a smooth, broad carapace and possess sizeable claws used for crushing and cutting prey. Mud Crab are generally very dark brown to mottled green in colour.",
      },
      {
        categoryName: "crab",
        discount: "-25%",
        featured: false,
        img: blueCrab,
        stock: "In Stock",
        productName: "Blue Crab",
        mainAmount: 250.0,
        subAmount: 350.0,
        type: kilo,
        para: "The blue crab's shell—called the “carapace”—is a blue to olive green. Shells can reach up to 9 inches across. Blue crab claws are bright blue, and mature females have red tips on their claws too. They have three pairs of walking legs and rear swimming legs that look like paddles.",
      },
      {
        categoryName: "crab",
        discount: "-33%",
        featured: false,
        img: seaCrab,
        stock: "In Stock",
        productName: "Sea Crab",
        mainAmount: 120.0,
        subAmount: 170.0,
        type: kilo,
        para: "Sea crabs are crustaceans that don't have a backbone and are protected by a hard shell. Their pincers are used catch food and to ﬁght off predators. The thousands of sea crab species can be found in oceans all around the world.",
      },
      {
        categoryName: "crab",
        discount: "-14%",
        featured: true,
        img: stoneCrab,
        stock: "In Stock",
        productName: "Stone Crab",
        mainAmount: 120.0,
        subAmount: 200.0,
        type: kilo,
        para: "Stone Crab can be found in both shallow, nearshore waters as well as deep, offshore benthic environments. Their preferred habitats include sandy/muddy bottoms, rocky outcrops, and seagrass beds.",
      },
    ],
    squid: [
      {
        categoryName: "squid",
        discount: "-50%",
        featured: false,
        img: european,
        stock: "In Stock",
        productName: "European squid",
        mainAmount: 150.0,
        subAmount: 200.0,
        type: kilo,
        para: "The European squid has a long, moderately slender and cylindrical body. Rhomboid ﬁns comprise two-thirds of the mantle length, though locomotion is via jet propulsion. The posterior border is slightly concave.",
      },
      {
        categoryName: "squid",
        discount: "-50%",
        featured: false,
        img: giant,
        stock: "In Stock",
        productName: "Giant squid",
        mainAmount: 200.0,
        subAmount: 300.0,
        type: kilo,
        para: "The giant squid is morphologically similar to smaller squid species, possessing a head, a mantle, and other features associated with cephalopods. It is characterized by the presence of two large ﬁns attached to its mantle, eight arms, and two long tentacles.",
      },
      {
        categoryName: "squid",
        discount: "-50%",
        featured: false,
        img: japaneseFlying,
        stock: "In Stock",
        productName: "Japanese Flying squid",
        mainAmount: 170.0,
        subAmount: 200.0,
        type: kilo,
        para: "The squid has eight arms and two tentacles with suction cups along the underside. In between the arms sits the beak, which housesa toothed, tongue-like appendage called the radula.",
      },
      {
        categoryName: "squid",
        discount: "-34%",
        featured: false,
        img: arrow,
        stock: "In Stock",
        productName: "Arrow squid",
        mainAmount: 110.0,
        subAmount: 150.0,
        type: kilo,
        para: "Both species of Arrow Squid have smooth cylindrical bodies with short pointed ﬁns. Their colour is white with tones of bronze on the mantle and head. The eyes are black. The ﬂesh is ﬁrm and ivory coloured, covered by a speckled membrane.",
      },
      {
        categoryName: "squid",
        discount: "-50%",
        featured: false,
        img: jumbo,
        stock: "In Stock",
        productName: "Jumbo squid",
        mainAmount: 300.0,
        subAmount: 400.0,
        type: kilo,
        para: "The jumbo squid (Dosidicus gigas) is the most abundant cephalopod species in the Southeast Paciﬁc Ocean, ranging from the North American coast to southern Chile. This elusive creature reaches up to 3 meters in length and weighs up to 50 kilograms.",
      },
    ],
    pickles_frozenItems: [
      {
        categoryName: "pickles_frozenItems",
        discount: "-34%",
        featured: false,
        img: fishPickle,
        stock: "In Stock",
        productName: `Fish Pickle ${gram100}`,
        mainAmount: 104.0,
        subAmount: 120.0,
        para: "Fish pickle is a delicious and tangy condiment made by marinating pieces of fish in a spicy mixture of vinegar, spices, and oil. This traditional delicacy, popular in many coastal regions, combines the rich flavors of fresh fish with the aromatic intensity of Indian spices.",
      },
      {
        categoryName: "pickles_frozenItems",
        discount: "-34%",
        featured: false,
        img: prawnpickle,
        stock: "In Stock",
        productName: `Prawn Pickle ${gram100}`,
        mainAmount: 50.0,
        subAmount: 65.0,
        para: "Prawn pickle is a tangy and spicy condiment made by marinating prawns in a flavorful mixture of vinegar, spices, and oil. This traditional delicacy is especially popular in coastal regions and combines the rich flavors of fresh prawns with aromatic Indian spices.",
      },
      {
        categoryName: "pickles_frozenItems",
        discount: "-34%",
        featured: false,
        img: fishKebabMasala,
        stock: "In Stock",
        productName: `Fish Kebab Masala ${gram}`,
        mainAmount: 29.0,
        subAmount: 58.0,
        para: "Fish Kebab Masala is a versatile and aromatic spice blend specifically crafted to elevate the flavors of fish kebabs. This masala mix combines a rich array of spices and herbs, offering a harmonious balance of heat, tang, and savory notes that infuse your fish kebabs with an irresistible taste.",
      },
      {
        categoryName: "pickles_frozenItems",
        discount: "-34%",
        featured: false,
        img: fishKulambu,
        stock: "In Stock",
        productName: `Fish Kulambu Masala ${gram100}`,
        mainAmount: 30.0,
        subAmount: 40.0,
        para: "Fish Kolambu Masala is a robust and aromatic spice blend specifically crafted to enhance the flavors of traditional South Indian fish curries. This masala mix combines an array of spices and herbs, providing a perfect balance of heat, tang, and aromatic depth that infuses your fish kolambu with an authentic and delectable taste.",
      },
      {
        categoryName: "pickles_frozenItems",
        discount: "-34%",
        featured: false,
        img: fish_65,
        stock: "In Stock",
        productName: `Fish 65 Masala ${gram}`,
        mainAmount: 59.0,
        subAmount: 100.0,
        para: "Fish 65 Masala is a specially crafted spice blend designed to create the iconic Fish 65, a popular South Indian appetizer. This masala mix combines a medley of spices and herbs, delivering a perfect balance of heat, tanginess, and savory notes that infuse your fish with a delectable and crispy coating.",
      },
    ],
    driedSeaFood: [
      {
        categoryName: "driedSeaFood",
        discount: "-34%",
        featured: false,
        img: driedAnchovy,
        stock: "In Stock",
        productName: "Dried Anchovies",
        mainAmount: 500.0,
        subAmount: 700.0,
        type: kilo,
        para: "These small, silvery ﬁsh are typically sun-dried or sometimes oven-dried until they become hard and brittle. Drying anchovies not only extends their shelf life but also intensiﬁes their unique umami ﬂavor.",
      },
      {
        categoryName: "driedSeaFood",
        discount: "-50%",
        featured: false,
        img: driedShankara,
        stock: "In Stock",
        productName: "Dried Shankara fish",
        mainAmount: 400.0,
        subAmount: 700.0,
        type: kilo,
        para: "Sankara ﬁsh, also known as Red snapper in English, is a top seafood favourite. Sankara is a popular ﬁsh with a ﬁrm texture, a distinctly sweet ﬂavour, and an imprssive nutritional proﬁle.",
      },
      {
        categoryName: "driedSeaFood",
        discount: "-14%",
        featured: true,
        img: driedShrimp,
        stock: "In Stock",
        productName: "Dried Baby shrimp",
        mainAmount: 450.0,
        subAmount: 800.0,
        type: kilo,
        para: "Dried shrimp are shrimp that have been sun-dried and shrunk to a thumbnail size. They are used in many East Asian, Southeast Asian and South Asian cuisines, imparting a unique umami taste.",
      },
      {
        categoryName: "driedSeaFood",
        discount: "-25%",
        featured: false,
        img: driedShell,
        stock: "In Stock",
        productName: "Dried Shell",
        mainAmount: 600.0,
        subAmount: 900.0,
        type: kilo,
        para: "The shell itself is the exoskeleton of a soft-bodied mollusk. Composed mainly of calcium carbonate, the shell is secreted by the animal and provides protection as well as support for various organs",
      },
      {
        categoryName: "driedSeaFood",
        discount: "-33%",
        featured: false,
        img: driedCroker,
        stock: "In Stock",
        productName: "Dried croaker fish",
        mainAmount: 400.0,
        subAmount: 600.0,
        type: kilo,
        para: "Dried Croaker Fish contains many essential nutrients, such as omega-3 fatty acids and high quality proteins. Croaker ﬁsh can be processed into many delicious dishes, but usually be dried because it has a strong taste, fast processing and convenient to use.",
      },
      {
        categoryName: "driedSeaFood",
        discount: "-14%",
        featured: true,
        img: driedMackrelFish,
        stock: "In Stock",
        productName: "Dried Mackrel Fish",
        mainAmount: 300.0,
        subAmount: 500.0,
        type: kilo,
        para: "Dry Mackerel Fish is salt-cured and dried mackerel ﬁsh. It is either sun-dried or dried in a food dehydrator until it is completely dry.",
      },
      {
        categoryName: "driedSeaFood",
        discount: "-33%",
        featured: false,
        img: driedPrawn,
        stock: "In Stock",
        productName: "Dried Prawn",
        mainAmount: 500.0,
        subAmount: 600.0,
        type: kilo,
        para: "Tasty Nibbles Dried Prawns are wild caught Prawns that have been hygienically dried and shrunk to a thumbnail size. They are used in many Indian, East Asian, Southeast Asian, and South Asian cuisines, unique umami taste.",
      },
      {
        categoryName: "driedSeaFood",
        discount: "-14%",
        featured: true,
        img: driedEel,
        stock: "In Stock",
        productName: "Dried eel fish",
        mainAmount: 300.0,
        subAmount: 500.0,
        type: kilo,
        para: "Eels are catadromous, meaning they primarily live in rivers and estuaries, but migrate out to the ocean - the Sargasso Sea, to spawn. American eels are an elongated ﬁsh, with fairly small ﬁns.",
      },
      {
        categoryName: "driedSeaFood",
        discount: "-14%",
        featured: true,
        img: driedBombayDuckFish,
        stock: "In Stock",
        productName: "Dried bombay duck fish",
        mainAmount: 300.0,
        subAmount: 500.0,
        type: kilo,
        para: "Bombay duck ﬁsh of the family Synodontidae, found in estuaries of northern India, where it is widely used as a food ﬁsh and, when dried,or brown in colour with small, dark speckles.",
      },
      {
        categoryName: "driedSeaFood",
        discount: "-14%",
        featured: true,
        img: driedSardine,
        stock: "In Stock",
        productName: "Dried sardine fish",
        mainAmount: 200.0,
        subAmount: 500.0,
        type: kilo,
        para: "Dried sardines are a popular ingredient in many cuisines, known for their strong flavor and nutritional benefits.Dried sardines are high in protein, making them an excellent source for muscle building and repair.",
      },
      {
        categoryName: "driedSeaFood",
        discount: "-14%",
        featured: true,
        img: driedRibbon,
        stock: "In Stock",
        productName: "Dried ribbon fish",
        mainAmount: 200.0,
        subAmount: 400.0,
        type: kilo,
        para: "Dried ribbon fish, often referred to as dried hairtail or dried ribbonfish, is a popular ingredient in various Asian cuisines.Rich in protein, making it a great option for muscle maintenance and growth.Contains beneficial omega-3s, promoting heart health. ",
      },
      {
        categoryName: "driedSeaFood",
        discount: "-14%",
        featured: true,
        img: driedPorgy,
        stock: "In Stock",
        productName: "Dried porgy fish",
        mainAmount: 200.0,
        subAmount: 400.0,
        type: kilo,
        para: "Dried porgy fish, often simply referred to as dried porgy, is a popular ingredient in various culinary traditions, especially in Mediterranean and Asian cuisines. Dried porgy is high in protein, making it a great source of essential amino acids.",
      },
    ],
  };

  const mapProductValue = productList[propsProductValue];

  // Convert to modal content

  const [moveContent, setMoveContent] = useState([]);

  // load more to show Extra product
  const [visibleProducts, setVisibleProducts] = useState(5);
  const [newlyLoaded, setNewlyLoaded] = useState(false);

  const loadMoreProducts = () => {
    setVisibleProducts((prevValue) => prevValue + 10);
    setNewlyLoaded(true);
  };

  useEffect(() => {
    setVisibleProducts(5);
  }, [propsProductValue]);

  useEffect(() => {
    if (newlyLoaded) {
      const timer = setTimeout(() => {
        setNewlyLoaded(false); 
      }, 500); 
      return () => clearTimeout(timer);
    }
  }, [newlyLoaded]);

  return (
    <div>
      <div className="product_div_overall" style={{ paddingBottom: "30px" }}>
        {mapProductValue.slice(0, visibleProducts).map((product,index) => (
          <div key={index} id="product_div_animation" className={`product_div ${newlyLoaded && index >= visibleProducts - 10 ? "fade_in" : ""}`} >
            <div className="product_discount_div">
              <div>
                <span className="product_dicount">{product.discount}</span>
                <span
                  className={
                    product.featured === true
                      ? "product_featured"
                      : "product_featured_none"
                  }
                >
                  Featured
                </span>
              </div>
              <div className="product_cart_div">
                <div>
                  <button className="product_heart" custom_tooltip="Upcoming">
                    <i className="fa-regular fa-heart"></i>
                  </button>
                </div>
                <div>
                  <button
                    className="product_eye"
                    custom_tooltip="Quick View"
                    onClick={() => {
                      setMoveContent(product);
                    }}
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalAllProduct"
                  >
                    <i className="fa-regular fa-eye"></i>
                  </button>
                </div>
              </div>
              <div
                className="modal fade"
                id="exampleModalAllProduct"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered modal-xl">
                  <div className="modal-content" id="product_modal_width">
                    <div className="modal-body" id="product_modal_content_overall">
                      <div className="productTwo_closeMob">
                        <button
                          id="productTwo_close"
                          type="button"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <i className="fa-solid fa-xmark fa-lg"></i>
                        </button>
                      </div>
                      <div className="product_modal_one">
                        <img src={moveContent.img} alt="product_img"></img>
                      </div>
                      <div className="product_modal_two">
                        <div className="productTwo_title">
                          <div>
                            <h4 className="productTwo_title_size">
                              {moveContent.productName}
                            </h4>
                          </div>
                          <div className="productTwo_closeDesktop">
                            <button
                              id="productTwo_close"
                              type="button"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              <i className="fa-solid fa-xmark fa-lg"></i>
                            </button>
                          </div>
                        </div>
                        <div>
                          <div className="productTwo_rateDiv">
                            <div className="product_rate">
                              <span className="productTwo_main_rate">
                                ₹ {moveContent.mainAmount} {moveContent.type}
                              </span>
                              <span className="productTwo_sub_rate">
                                ₹{moveContent.subAmount}
                              </span>
                            </div>
                            <div className="productTwo_stock_gap">
                              <span
                                className={
                                  moveContent.stock === "In Stock"
                                    ? "productStock_update"
                                    : "productOutOf_stock_update"
                                }
                              >
                                <span id="productTwo_dot">•</span>{" "}
                                {moveContent.stock === "In Stock"
                                  ? "In Stock"
                                  : "Out Of Stock"}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="productTwo_contentDiv">
                          <p className="productTwo_para">{moveContent.para}</p>
                        </div>
                        <div className="productTwo_BuyNow">
                          <button
                            className="productTwo_enquiryBtn"
                            onClick={handleChatSubmit}
                          >
                            <i
                              className="fa-solid fa-phone"
                              style={{ color: "#ffffff", fontSize: "13.5px" }}
                            ></i>
                            <span style={{ marginLeft: "6px" }}>Buy Now</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="product_img_div">
                <img className="product_img" src={product.img} alt="demo" />
              </div>
            </div>
            <div>
              <div>
                <ul
                  className={
                    product.stock === "In Stock"
                      ? "stock_update"
                      : "outOf_stock_update"
                  }
                >
                  <li>
                    {product.stock === "In Stock" ? "In Stock" : "Out Of Stock"}
                  </li>
                </ul>
              </div>
              <div>
                <Link className="product_name_link">{product.productName}</Link>
              </div>
              <div className="product_rate">
                <span className="product_main_rate">
                  ₹ {product.mainAmount} {product.type}
                </span>
                <span className="product_sub_rate">₹{product.subAmount}</span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* load More button */}
      {visibleProducts < mapProductValue.length && (
        <div className="product_load_more">
          <button className="product_load_more_btn" onClick={loadMoreProducts}>
            <FontAwesomeIcon
              icon={faArrowDown}
              className="product_load_more_btn_icon"
            />
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

export const HotDeals = ({ time }) => {
  const kilo = " / kg";

  const gram = "- (50 g)";

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    rtl: true,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 924,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 642,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const productList = [
    {
      discount: "-34%",
      featured: false,
      img: salmon,
      stock: "In Stock",
      productName: "Salmon fish",
      mainAmount: 400.0,
      subAmount: 600.0,
      type: kilo,
      para: "They're found in tributaries of the Paciﬁc and Atlantic Oceans, and most species are anadromous: They are born in streams and rivers, migrate out to the open sea, and then return to freshwater again to reproduce. Salmons have color throughout their lifetimes.",
    },
    {
      discount: "-50%",
      featured: false,
      img: rohu,
      stock: "In Stock",
      productName: "Rohu fish",
      mainAmount: 100.0,
      subAmount: 150.0,
      type: kilo,
      para: "The rohu is a large, silver-colored ﬁsh of typical cyprinid shape, with a conspicuously arched head. Adults can reach a maximum weight of 45 kg (99 lb) and maximum length of 2 m (6.6 ft), but average around 1⁄2 m (1.6 ft).",
    },
    {
      discount: "-14%",
      featured: true,
      img: blackTigerShrimp,
      stock: "In Stock",
      productName: "Black Tiger Shrimp",
      mainAmount: 350.0,
      subAmount: 450.0,
      type: kilo,
      para: "The Black Tiger Shrimp's natural range is vast, from the east coast of Africa, throughout the Indian Ocean, and in South Asia. Black Tiger Shrimp are named for their large size and striped tails and can reach a length of over a foot in some cases.",
    },
    {
      discount: "-25%",
      featured: false,
      img: seaCrab,
      stock: "In Stock",
      productName: "Sea Crab",
      mainAmount: 120.0,
      subAmount: 170.0,
      type: kilo,
      para: "Sea crabs are crustaceans that don't have a backbone and are protected by a hard shell. Their pincers are used catch food and to ﬁght off predators. The thousands of sea crab species can be found in oceans all around the world.",
    },
    {
      discount: "-33%",
      featured: false,
      img: jumbo,
      stock: "In Stock",
      productName: "Jumbo Squid",
      mainAmount: 300.0,
      subAmount: 400.0,
      type: kilo,
      para: "The jumbo squid (Dosidicus gigas) is the most abundant cephalopod species in the Southeast Paciﬁc Ocean, ranging from the North American coast to southern Chile. This elusive creature reaches up to 3 meters in length and weighs up to 50 kilograms.",
    },
    {
      discount: "-14%",
      featured: true,
      img: driedBombayDuckFish,
      stock: "In Stock",
      productName: "Dried bombay duck fish",
      mainAmount: 300.0,
      subAmount: 500.0,
      type: kilo,
      para: "Bombay duck ﬁsh of the family Synodontidae, found in estuaries of northern India, where it is widely used as a food ﬁsh and, when dried,or brown in colour with small, dark speckles.",
    },
    {
      discount: "-25%",
      featured: false,
      img: fishKebabMasala,
      stock: "In Stock",
      productName: `Fish Kebab Masala ${gram}`,
      mainAmount: 29.0,
      subAmount: 58.0,
      para: "Fish Kebab Masala is a versatile and aromatic spice blend specifically crafted to elevate the flavors of fish kebabs. This masala mix combines a rich array of spices and herbs, offering a harmonious balance of heat, tang, and savory notes that infuse your fish kebabs with an irresistible taste.",
    },
  ];

  // Convert to modal content

  const whatsappNumber = "8867906287";

  const whatsappUrl = `https://wa.me/${whatsappNumber}`;

  const handleChatSubmit = (e) => {
    e.preventDefault();
    window.open(whatsappUrl, "_blank");
  };

  const [moveContent, setMoveContent] = useState([]);

  return (
    <div>
      <div className="hotDeal_title">
        <div className="hotDeal_inner_title">
          <h5 className="hotDeal_title_content">Hot Deals!</h5>
          <h6 className="hotDeal_subtitle_content">
            Remains until the end of the offer
          </h6>
        </div>
        <div>
          <div className="hotDeal_timer_overall">
            <div>
              <span className="hotDeal_time">{time.startDay}</span>
            </div>
            <div>
              <span className="hotDeal_time_dot">:</span>
            </div>
            <div>
              <span className="hotDeal_time">{time.startHours}</span>
            </div>
            <div>
              <span>:</span>
            </div>
            <div>
              <span className="hotDeal_time">{time.startMinutes}</span>
            </div>
            <div>
              <span>:</span>
            </div>
            <div>
              <span className="hotDeal_time">{time.startSeconds}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="slider-container">
        <Slider {...settings}>
          {productList.map((product,index) => (
            <div id="hotDeal_div_overall" key={index}>
              <div className="product_div">
                <div className="product_discount_div">
                  <div>
                    <span className="product_dicount">{product.discount}</span>
                    <span
                      className={
                        product.featured === true
                          ? "product_featured"
                          : "product_featured_none"
                      }
                    >
                      Featured
                    </span>
                  </div>

                  <div className="product_cart_div">
                    <div>
                      <button
                        className="product_heart"
                        custom_tooltip="Upcoming"
                      >
                        <i className="fa-regular fa-heart"></i>
                      </button>
                    </div>
                    <div>
                      <button
                        className="product_eye"
                        custom_tooltip="Quick View"
                        onClick={() => {
                          setMoveContent(product);
                        }}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#modalHotDeals"
                      >
                        <i className="fa-regular fa-eye"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="product_img_div">
                    <img className="product_img" src={product.img} alt="demo" />
                  </div>
                </div>

                <div>
                  <div>
                    <ul
                      className={
                        product.stock === "In Stock"
                          ? "stock_update"
                          : "outOf_stock_update"
                      }
                    >
                      <li>
                        {product.stock === "In Stock"
                          ? "In Stock"
                          : "Out Of Stock"}
                      </li>
                    </ul>
                  </div>
                  <div>
                    <Link className="product_name_link">
                      {product.productName}
                    </Link>
                  </div>
                  <div className="product_rate">
                    <span className="product_main_rate">
                      ₹ {product.mainAmount} {product.type}
                    </span>
                    <span className="product_sub_rate">
                      ₹{product.subAmount}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>

        <div
          className="modal fade"
          id="modalHotDeals"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content" id="product_modal_width">
              <div className="modal-body" id="product_modal_content_overall">
                <div className="productTwo_closeMob">
                  <button
                    id="productTwo_close"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="fa-solid fa-xmark fa-lg"></i>
                  </button>
                </div>
                <div className="product_modal_one">
                  <img src={moveContent.img} alt="product_img"></img>
                </div>
                <div className="product_modal_two">
                  <div className="productTwo_title">
                    <div>
                      <h4 className="productTwo_title_size">
                        {moveContent.productName}
                      </h4>
                    </div>
                    <div className="productTwo_closeDesktop">
                      <button
                        id="productTwo_close"
                        type="button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fa-solid fa-xmark fa-lg"></i>
                      </button>
                    </div>
                  </div>
                  <div>
                    <div className="productTwo_rateDiv">
                      <div className="product_rate">
                        <span className="productTwo_main_rate">
                          ₹ {moveContent.mainAmount} {moveContent.type}
                        </span>
                        <span className="productTwo_sub_rate">
                          ₹{moveContent.subAmount}
                        </span>
                      </div>
                      <div className="productTwo_stock_gap">
                        <span
                          className={
                            moveContent.stock === "In Stock"
                              ? "productStock_update"
                              : "productOutOf_stock_update"
                          }
                        >
                          <span id="productTwo_dot">•</span>{" "}
                          {moveContent.stock === "In Stock"
                            ? "In Stock"
                            : "Out Of Stock"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="productTwo_contentDiv">
                    <p className="productTwo_para">{moveContent.para}</p>
                  </div>
                  <div className="productTwo_BuyNow">
                    <button
                      className="productTwo_enquiryBtn"
                      onClick={handleChatSubmit}
                    >
                      <i
                        className="fa-solid fa-phone"
                        style={{ color: "#ffffff", fontSize: "13.5px" }}
                      ></i>
                      <span style={{ marginLeft: "6px" }}>Buy Now</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PropularProduct = () => {
  const kilo = " / kg";

  const gram = "- (50 g)";

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 924,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 642,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const productList = [
    {
      discount: "-34%",
      featured: false,
      img: tuna,
      stock: "In Stock",
      productName: "Tuna Fish",
      mainAmount: 100.0,
      subAmount: 200.0,
      type: kilo,
      para: "Tuna are remarkable and impressive wild animals. The Atlantic blueﬁn can reach ten feet in length and weigh as much as 2,000 pounds (more than a horse).Their specialized body shape, ﬁns, and scales enable some species of tuna to swim as fast as 43 miles per hour.",
    },
    {
      discount: "-50%",
      featured: false,
      img: northernPrawn,
      stock: "In Stock",
      productName: "Northern Prawn",
      mainAmount: 200.0,
      subAmount: 250.0,
      type: kilo,
      para: "The Northern prawn is a hermaphrodite and starts its life as a male after 4-5 years it turns female. The species is found mostly in areas with soft, muddy sediment where temperatures range from 1-6°C at a depth between 150 and 600 meters.",
    },
    {
      discount: "-14%",
      featured: true,
      img: tilapia,
      stock: "In Stock",
      productName: "Thilapia fish",
      mainAmount: 100.0,
      subAmount: 150.0,
      type: kilo,
      para: "Thilapia are a hardy, fast growing ﬁsh, that can live up to ten years and reach ten pounds in weight. Thilapia are shaped like a sunﬁsh or crappie and are easily identiﬁable by the interrupted lateral line characteristic of the Chiclid family of ﬁshes.",
    },
    {
      discount: "-25%",
      featured: false,
      img: european,
      stock: "In Stock",
      productName: "European squid",
      mainAmount: 150.0,
      subAmount: 200.0,
      type: kilo,
      para: "The European squid has a long, moderately slender and cylindrical body. Rhomboid ﬁns comprise two-thirds of the mantle length, though locomotion is via jet propulsion. The posterior border is slightly concave.",
    },
    {
      discount: "-33%",
      featured: false,
      img: mud,
      stock: "In Stock",
      productName: "Mud Crab",
      mainAmount: 120.0,
      subAmount: 170.0,
      type: kilo,
      para: "Mud Crab are a member of the family Portunidae. They are large crabs with a smooth, broad carapace and possess sizeable claws used for crushing and cutting prey. Mud Crab are generally very dark brown to mottled green in colour.",
    },
    {
      discount: "-14%",
      featured: true,
      img: milk,
      stock: "In Stock",
      productName: "Milk fish",
      mainAmount: 200.0,
      subAmount: 300.0,
      type: kilo,
      para: "Milkﬁsh have a spindle-like shape that is moderately compressed, smooth and streamlined. Its body color is silvery on the belly and sides, grading to olive green or blue on the back. The wild catch is limited to just slightly more than one percent of total world production.",
    },
    {
      discount: "-25%",
      featured: false,
      img: fish_65,
      stock: "In Stock",
      productName: `Fish 65 Masala ${gram}`,
      mainAmount: 59.0,
      subAmount: 100.0,
      para: "Fish 65 Masala is a specially crafted spice blend designed to create the iconic Fish 65, a popular South Indian appetizer. This masala mix combines a medley of spices and herbs, delivering a perfect balance of heat, tanginess, and savory notes that infuse your fish with a delectable and crispy coating.",
    },
  ];

  // Convert to modal content

  const whatsappNumber = "8867906287";

  const whatsappUrl = `https://wa.me/${whatsappNumber}`;

  const handleChatSubmit = (e) => {
    e.preventDefault();
    window.open(whatsappUrl, "_blank");
  };

  const [moveContent, setMoveContent] = useState([]);

  // navigate shop page

  const handleClickToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div className="hotDeal_title">
        <div className="hotDeal_inner_title">
          <h5 className="hotDeal_title_content">Recommend Products</h5>
          {/* <span className="hotDeal_subtitle_content"></span> */}
        </div>
        <div>
          <Link
            to={"shop"}
            smooth="true"
            duration={500}
            className="view_all_btn"
            onClick={handleClickToTop}
          >
            <div>
              <span>View All</span>
            </div>
            <div>
              <i
                id="viewAll_arrow_icon"
                className="fa-solid fa-arrow-right-long"
              ></i>
            </div>
          </Link>
        </div>
      </div>
      <div className="slider-container">
        <Slider {...settings}>
          {productList.map((product,index) => (
            <div id="hotDeal_div_overall" key={index}>
              <div className="product_div">
                <div className="product_discount_div">
                  <div>
                    <span className="product_dicount">{product.discount}</span>
                    <span
                      className={
                        product.featured === true
                          ? "product_featured"
                          : "product_featured_none"
                      }
                    >
                      Featured
                    </span>
                  </div>

                  <div className="product_cart_div">
                    <div>
                      <button
                        className="product_heart"
                        custom_tooltip="Upcoming"
                      >
                        <i className="fa-regular fa-heart"></i>
                      </button>
                    </div>
                    <div>
                      <button
                        className="product_eye"
                        custom_tooltip="Quick View"
                        onClick={() => {
                          setMoveContent(product);
                        }}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#modalRecommendProduct"
                      >
                        <i className="fa-regular fa-eye"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="product_img_div">
                    <img className="product_img" src={product.img} alt="demo" />
                  </div>
                </div>

                <div>
                  <div>
                    <ul
                      className={
                        product.stock === "In Stock"
                          ? "stock_update"
                          : "outOf_stock_update"
                      }
                    >
                      <li>
                        {product.stock === "In Stock"
                          ? "In Stock"
                          : "Out Of Stock"}
                      </li>
                    </ul>
                  </div>
                  <div>
                    <Link className="product_name_link">
                      {product.productName}
                    </Link>
                  </div>
                  <div className="product_rate">
                    <span className="product_main_rate">
                      ₹ {product.mainAmount} {product.type}
                    </span>
                    <span className="product_sub_rate">
                      ₹{product.subAmount}{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>

        <div
          className="modal fade"
          id="modalRecommendProduct"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content" id="product_modal_width">
              <div className="modal-body" id="product_modal_content_overall">
                <div className="productTwo_closeMob">
                  <button
                    id="productTwo_close"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="fa-solid fa-xmark fa-lg"></i>
                  </button>
                </div>
                <div className="product_modal_one">
                  <img src={moveContent.img} alt="product_img"></img>
                </div>
                <div className="product_modal_two">
                  <div className="productTwo_title">
                    <div>
                      <h4 className="productTwo_title_size">
                        {moveContent.productName}
                      </h4>
                    </div>
                    <div className="productTwo_closeDesktop">
                      <button
                        id="productTwo_close"
                        type="button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fa-solid fa-xmark fa-lg"></i>
                      </button>
                    </div>
                  </div>
                  <div>
                    <div className="productTwo_rateDiv">
                      <div className="product_rate">
                        <span className="productTwo_main_rate">
                          ₹ {moveContent.mainAmount} {moveContent.type}
                        </span>
                        <span className="productTwo_sub_rate">
                          ₹{moveContent.subAmount}
                        </span>
                      </div>
                      <div className="productTwo_stock_gap">
                        <span
                          className={
                            moveContent.stock === "In Stock"
                              ? "productStock_update"
                              : "productOutOf_stock_update"
                          }
                        >
                          <span id="productTwo_dot">•</span>{" "}
                          {moveContent.stock === "In Stock"
                            ? "In Stock"
                            : "Out Of Stock"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="productTwo_contentDiv">
                    <p className="productTwo_para">{moveContent.para}</p>
                  </div>
                  <div className="productTwo_BuyNow">
                    <button
                      className="productTwo_enquiryBtn"
                      onClick={handleChatSubmit}
                    >
                      <i
                        className="fa-solid fa-phone"
                        style={{ color: "#ffffff", fontSize: "13.5px" }}
                      ></i>
                      <span style={{ marginLeft: "6px" }}>Buy Now</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FilterProducts = ({ propsProduct }) => {
  const whatsappNumber = "8867906287";

  const whatsappUrl = `https://wa.me/${whatsappNumber}`;

  const handleChatSubmit = (e) => {
    e.preventDefault();
    window.open(whatsappUrl, "_blank");
  };

  const kilo = " / kg";

  const gram = "- (50 g)";

  const gram100 = "- (100 g)";

  const productList = {
    seaFish: [
      {
        categoryName: "seaFish",
        discount: "-34%",
        featured: false,
        img: anchovy,
        stock: "In Stock",
        productName: "Anchovy fish",
        mainAmount: 250.0,
        subAmount: 350.0,
        type: kilo,
        para: "Anchovies are small, green ﬁsh with blue reﬂections due to a silver-colored longitudinal stripe that runs from the base of the caudal (tail) ﬁn.They range from 2 to 40 centimeters(1 to 15+1⁄2 inches) in adult length, and their more slender ﬁsh in northern populations.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: herring,
        stock: "In Stock",
        productName: "Herring fish",
        mainAmount: 200.0,
        subAmount: 250.0,
        type: kilo,
        para: "Herring have a blue-green upper body with silvery sides and are devoid of markings.The body is laterally compressed and the scales along the underside project in a slightly serrated arrangement.Scales are large and easily removed.Their tails are deeply forked and they lack adipose ﬁns.",
      },
      {
        categoryName: "seaFish",
        discount: "-14%",
        featured: true,
        img: croaker,
        stock: "In Stock",
        productName: "Croaker fish",
        mainAmount: 150.0,
        subAmount: 200.0,
        type: kilo,
        para: "The Atlantic croaker is a member of the drum and croaker family. It has a slightly elongated, fusiform body with a rounded dorsal proﬁle and nearly straight ventral proﬁle. It has a moderately large and sub terminal mouth, and there are between six and ten tiny chin barbells.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: salmon,
        stock: "In Stock",
        productName: "Salmon fish",
        mainAmount: 400.0,
        subAmount: 600.0,
        type: kilo,
        para: "They're found in tributaries of the Paciﬁc and Atlantic Oceans, and most species are anadromous: They are born in streams and rivers, migrate out to the open sea, and then return to freshwater again to reproduce. Salmons have color throughout their lifetimes.",
      },
      {
        categoryName: "seaFish",
        discount: "-33%",
        featured: false,
        img: mackerel,
        stock: "In Stock",
        productName: "Mackerel fish",
        mainAmount: 150.0,
        subAmount: 250.0,
        type: kilo,
        para: "Mackerels are rounded and torpedo-shaped, with a slender, keeled tail base, a forked tail,and a row of small inlets behind the dorsal and anal ﬁns. They are carnivorous ﬁshes and feed on plankton, crustaceans, mollusks, ﬁsh eggs, and small ﬁsh.",
      },
      {
        categoryName: "seaFish",
        discount: "-14%",
        featured: true,
        img: blackPomfret,
        stock: "In Stock",
        productName: "Black Pomfret Fish",
        mainAmount: 600.0,
        subAmount: 800.0,
        type: kilo,
        para: "The black pomfret has a compressed deep body with dorsal and ventral proﬁles being equally convex.This species has small dark grey scales which cover the entirety of the body.The fork measurement of Black Pomfret is commonly 30 cm but can also range up to 75 cm in length.",
      },
      {
        categoryName: "seaFish",
        discount: "-50%",
        featured: false,
        img: bream,
        stock: "In Stock",
        productName: "Bream fish",
        mainAmount: 130.0,
        subAmount: 200.0,
        type: kilo,
        para: "The bream is a medium-sized ﬁsh and a member of the carp family, so displays the typical carp shape. It is a bottom-feeding ﬁsh that preys on worms, snails and pea mussels. It lives in large ponds, lakes and slow-moving rivers where it congregates in quite large shoals.",
      },
      {
        categoryName: "seaFish",
        discount: "-50%",
        featured: false,
        img: ribbon,
        stock: "In Stock",
        productName: "Ribbon fish",
        mainAmount: 150.0,
        subAmount: 200.0,
        type: kilo,
        para: "A ribbon-shaped ﬁsh, very thin from side to side, the oarﬁsh may grow to a length of about 9 meters (30.5 feet) and a weight of 300 kg (660 pounds).It is shiny silver in color, with long, red, oarlike rises as a manelike crest on top of the head.",
      },
      {
        categoryName: "seaFish",
        discount: "-14%",
        featured: true,
        img: tuna,
        stock: "In Stock",
        productName: "Tuna fish",
        mainAmount: 100.0,
        subAmount: 200.0,
        type: kilo,
        para: "Tuna are remarkable and impressive wild animals. The Atlantic blueﬁn can reach ten feet in length and weigh as much as 2,000 pounds (more than a horse).Their specialized body shape, ﬁns, and scales enable some species of tuna to swim as fast as 43 miles per hour.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: milk,
        stock: "In Stock",
        productName: "Milk fish",
        mainAmount: 200.0,
        subAmount: 300.0,
        type: kilo,
        para: "Milkﬁsh have a spindle-like shape that is moderately compressed, smooth and streamlined. Its body color is silvery on the belly and sides, grading to olive green or blue on the back. The wild catch is limited to just slightly more than one percent of total world production.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: barracuda,
        stock: "In Stock",
        productName: "Barracuda fish",
        mainAmount: 200.0,
        subAmount: 270.0,
        type: kilo,
        para: "Barracudas are known for their sleek, elongated bodies and impressive speed, making them skilled predators in the ocean. They have sharp teeth and a voracious appetite, primarily feeding on smaller fish and sometimes squid.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: bombayduck,
        stock: "In Stock",
        productName: "Bombay duck fish",
        mainAmount: 100.0,
        subAmount: 150.0,
        type: kilo,
        para: "Bombay duck, also known as Bombil (scientific name: Harpadon nehereus), is a fish found primarily in the waters of the Arabian Sea and the Bay of Bengal. Despite its name, it's not a duck but rather a type of lizardfish.Bombay duck is typically found in shallow waters and is often caught near the sea floor. It prefers sandy or muddy substrates.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: dart,
        stock: "In Stock",
        productName: "Dart fish",
        mainAmount: 500.0,
        subAmount: 700.0,
        type: kilo,
        para: "Dartfish, belonging to the family Callionymidae, are small, colorful fish commonly found in tropical and subtropical waters, often inhabiting sandy or muddy substrates near coral reefs.Dartfish prefer shallow waters and are often seen hovering above the substrate, where they feed on small crustaceans and plankton.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: emperor,
        stock: "In Stock",
        productName: "Emperor fish",
        mainAmount: 150.0,
        subAmount: 200.0,
        type: kilo,
        para: "Emperor fish, primarily belonging to the family Lethrinidae, are popular in both commercial and recreational fishing.  Emperor fish have a robust body with a slightly compressed shape, large mouths, and distinctive coloring. They often feature a mix of blue, yellow, and gray hues, with horizontal stripes.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: goat,
        stock: "In Stock",
        productName: "Goat fish",
        mainAmount: 200.0,
        subAmount: 250.0,
        type: kilo,
        para: "Goatfish, belonging to the family Mullidae, are known for their distinctive appearance and behavior.Goatfish have elongated bodies with two distinct barbels on their chins, which they use to probe the sand for food. They often have colorful markings, including stripes or spots.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: grouper,
        stock: "In Stock",
        productName: "Grouper fish",
        mainAmount: 200.0,
        subAmount: 250.0,
        type: kilo,
        para: "Grouper fish, belonging to the family Epinephelidae, are a diverse group of fish found primarily in warmer waters around the worldGroupers are characterized by their stout bodies, large mouths, and strong jaws. They often have vibrant colors and patterns that can vary significantly between species.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: grunter,
        stock: "In Stock",
        productName: "Grunter fish",
        mainAmount: 300.0,
        subAmount: 450.0,
        type: kilo,
        para: "Grunter fish, belonging to the family Pomadasyidae, are known for their distinctive grunting sounds, which they produce using their swim bladders.Grunter fish typically have elongated bodies and flattened heads. Their coloration can vary, but many have a silver or grayish hue with dark stripes or spots.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: haddock,
        stock: "In Stock",
        productName: "Haddock fish",
        mainAmount: 170.0,
        subAmount: 200.0,
        type: kilo,
        para: "Haddock (Melanogrammus aeglefinus) is a popular fish known for its mild flavor and flaky white flesh.Haddock is primarily found in the North Atlantic Ocean, often inhabiting deeper waters, typically on the continental shelf. They prefer sandy or muddy bottomsHaddock are carnivorous, feeding on a variety of prey, including small fish, crustaceans, and mollusks.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: hathead,
        stock: "In Stock",
        productName: "Hathead fish",
        mainAmount: 150.0,
        subAmount: 250.0,
        type: kilo,
        para: "Hathead fish, also known as the hatchetfish or Mola mola, refer to several species, but typically the term refers to the species in the family Mola. Hathead fish are notable for their unique, flattened bodies and large, rounded heads. They have a distinct shape that resembles a giant floating disc.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: hilsa,
        stock: "In Stock",
        productName: "Hilsa fish",
        mainAmount: 1500.0,
        subAmount: 2500.0,
        type: kilo,
        para: "Hilsa fish, known scientifically as Tenualosa ilisha, is a highly prized species in South Asia, particularly in India, Bangladesh, and Pakistan.Hilsa has a distinctive silver body with a bluish or greenish back and a deeply forked tail.Hilsa is primarily found in the Bay of Bengal and the rivers that flow into it, such as the Ganges and Brahmaputra. .",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: jew,
        stock: "In Stock",
        productName: "Jew fish",
        mainAmount: 150.0,
        subAmount: 250.0,
        type: kilo,
        para:"Jewfish, commonly known as the goliath grouper (Epinephelus itajara), is a large fish found in tropical and subtropical waters. Jewfish have a robust, elongated body with a broad head and large mouth.Jewfish are carnivorous, feeding on a variety of prey, including fish, crustaceans, and octopuses. They are powerful predators and can consume large prey." 
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: lady,
        stock: "In Stock",
        productName: "Lady fish",
        mainAmount: 200.0,
        subAmount: 300.0,
        type: kilo,
        para: "Ladyfish, scientifically known as Elops saurus, is a species found primarily in coastal waters and estuaries. Ladyfish have elongated, sleek bodies with a silvery appearance and a somewhat forked tail.Ladyfish are carnivorous, primarily feeding on small fish and invertebrates. They are known for their active and aggressive feeding behavior.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: moon,
        stock: "In Stock",
        productName: "Moon fish",
        mainAmount: 100.0,
        subAmount: 150.0,
        type: kilo,
        para: "Moonfish, often referred to as opah (Lampris guttatus), is a unique species known for its distinctive appearance and behavior.   Moonfish have a round, flat body that resembles a disc. They have vibrant coloration, typically featuring a combination of silvery blue, red, and orange hues. Their large pectoral fins give them a unique look.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: mullet,
        stock: "In Stock",
        productName: "Mullet fish",
        mainAmount: 250.0,
        subAmount: 300.0,
        type: kilo,
        para: "Mullet fish, belonging to the family Mugilidae, are a group of fish known for their robust bodies and distinctive behaviors.Mullet have elongated, cylindrical bodies with a bluish or greenish back and silver sides. They typically have a forked tail and can grow up to 30 inches (76 cm) long, depending on the species.mullet are commonly found in coastal waters, estuaries, and rivers. ",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: perch,
        stock: "In Stock",
        productName: "Perch fish",
        mainAmount: 200.0,
        subAmount: 250.0,
        type: kilo,
        para: "Perch fish, particularly those from the family Percidae, are popular freshwater fish known for their distinctive appearance and behavior.Perch have elongated bodies with a slightly flattened shape. They typically feature vertical stripes on their sides, with the coloration varying from greenish to yellow. They have spiny dorsal fins that add to their distinctive look.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: pearlspot,
        stock: "In Stock",
        productName: "Pearl spot fish",
        mainAmount: 140.0,
        subAmount: 200.0,
        type: kilo,
        para: "Pearl spot fish, commonly known as Etroplus suratensis, is a popular fish species, particularly in South Asia. The pearl spot fish has a distinctive oval body, with a greenish or bluish hue and several prominent dark spots resembling pearls along its sides.Pearl spot fish are omnivorous, feeding on a variety of food sources, including algae, detritus, small insects, and zooplankton.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: porgy,
        stock: "In Stock",
        productName: "Porgy fish",
        mainAmount: 200.0,
        subAmount: 300.0,
        type: kilo,
        para: "Porgy fish, belonging to the family Sparidae, are a group of fish known for their robust bodies and strong, flat teeth. Porgies are commonly found in warm coastal waters, usually inhabiting sandy or rocky bottoms near reefs. They can be found in both shallow and deeper waters.Porgies typically have a deep, laterally compressed body with a blunt snout and large eyes. ",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: ray,
        stock: "In Stock",
        productName: "Ray fish",
        mainAmount: 120.0,
        subAmount: 200.0,
        type: kilo,
        para: "Ray fish, commonly referred to simply as Rays, belong to the order Myliobatiformes and include a diverse group of species such as stingrays, manta rays, and skates.Rays have flattened bodies with large pectoral fins that extend from their bodies, giving them a disc-like shape. They often have long tails, which can be equipped with stingers in species like stingrays. ",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: redsnapper,
        stock: "In Stock",
        productName: "Red snapper fish",
        mainAmount: 200.0,
        subAmount: 300.0,
        type: kilo,
        para: "Red snapper, commonly known as Lutjanus campechanus, is a highly valued fish found in warmer waters. Red snapper has a distinctive reddish-pink coloration, with a slightly elongated body and a pointed snout.Red snapper is highly regarded for its firm, flaky flesh and mild flavor, making it a popular choice in many cuisines. It can be grilled, baked, or fried.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: sardine,
        stock: "In Stock",
        productName: "Sardines fish",
        mainAmount: 100.0,
        subAmount: 150.0,
        type: kilo,
        para: "Sardines are small, schooling fish belonging to the family Clupeidae. They are known for their rich flavor and high nutritional value. Sardines have a streamlined body with a silvery sheen, which helps them evade predatorsSardines inhabit coastal and offshore waters, often forming large schools in nutrient-rich areas. They prefer temperate and subtropical oceans.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: scad,
        stock: "In Stock",
        productName: "Scad fish",
        mainAmount: 300.0,
        subAmount: 500.0,
        type: kilo,
        para: "Scad fish typically refers to various species in the family Carangidae, commonly found in tropical and subtropical waters. Scad fish are usually found in schools near the surface of the ocean, often around reefs and inshore areas.Scad are sought after for both commercial and recreational fishing. They are also popular as bait fish for larger game fish.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: seer,
        stock: "In Stock",
        productName: "Seer fish",
        mainAmount: 400.0,
        subAmount: 600.0,
        type: kilo,
        para: "Seer fish, commonly known as Scomberomorus commerson, is a popular species in tropical and subtropical waters, often prized for its flavor and culinary value. grading to olive green or blue on the back. The wild catch is limited to just slightly more than one percent of total world production.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: shark,
        stock: "In Stock",
        productName: "Shark fish",
        mainAmount: 200.0,
        subAmount: 250.0,
        type: kilo,
        para: "Sharks have fascinating adaptations, such as multiple rows of teeth, a keen sense of smell, and the ability to detect electrical fields in the water. Sharks are often portrayed as dangerous predators, but many species pose little to no threat to humans. Educating people about their role in the ecosystem can help change perceptions.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: silver,
        stock: "In Stock",
        productName: "Silver fish",
        mainAmount: 150.0,
        subAmount: 200.0,
        type: kilo,
        para: " Silverfish (Lepisma saccharinum) are small, wingless insects known for their silvery, metallic appearance and fish-like movements.Silverfish feed on starches and sugars, often consuming paper, glue, and even dead insects. They can damage books, wallpaper, and other household items. Silverfish can live for several years, making them persistent pests if not managed.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: surgeon,
        stock: "In Stock",
        productName: "Surgeon fish",
        mainAmount: 100.0,
        subAmount: 200.0,
        type: kilo,
        para: "Surgeonfish belong to the family Acanthuridae and are also known as tangs or palette fish.Surgeonfish are commonly found in coral reefs and warm, shallow waters of the Indo-Pacific and the Caribbean. Surgeonfish often form schools and exhibit interesting social behaviors. They can be territorial and may engage in displays to establish dominance.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: sword,
        stock: "In Stock",
        productName: "Sword fish",
        mainAmount: 200.0,
        subAmount: 250.0,
        type: kilo,
        para: "Swordfish are large, powerful fish known for their elongated, flattened bills resembling swords. Swordfish are found in warm and temperate waters around the world, often in the Atlantic, Indian, and Pacific Oceans.TSwordfish primarily feed on squid, fish, and crustaceans. Their sharp bill is used to slash at prey, making it easier to catch.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: tonguesole,
        stock: "In Stock",
        productName: "Tongue sole fish",
        mainAmount: 200.0,
        subAmount: 300.0,
        type: kilo,
        para: "Tongue soles are flatfish, meaning they have a flattened body that allows them to lie on the sea floor. They have a unique shape with both eyes on one side of their body.Tongue sole fish are commercially fished and are considered a delicacy in many cuisines, particularly in Asian and European dishes.Tongue sole fish spawn in warmer months. They release eggs into the water column, where fertilization occurs externally.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: travelly,
        stock: "In Stock",
        productName: "Travelly fish",
        mainAmount: 200.0,
        subAmount: 250.0,
        type: kilo,
        para: "Trevally fish are found in tropical and subtropical waters around the world, primarily in the Indian and Pacific Oceans. Trevallies are predatory fish that feed on smaller fish, squid, and crustaceans. Trevally typically spawn in deeper waters, with females releasing eggs into the water column for fertilization by males.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: whitepomfret,
        stock: "In Stock",
        productName: "White pomfret fish",
        mainAmount: 600.0,
        subAmount: 800.0,
        type: kilo,
        para: "White pomfret is a flat, oval-shaped fish with a silver-white coloration and a slightly compressed body. It has a smooth texture and can grow up to 3 feet long.White pomfret is primarily found in the warm waters of the Indian Ocean and the western Pacific Ocean.White pomfret primarily feeds on small fish, crustaceans, and other marine organisms.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: yellowscad,
        stock: "In Stock",
        productName: "Yellow scad fish",
        mainAmount: 250.0,
        subAmount: 350.0,
        type: kilo,
        para: "Yellow scad are small to medium-sized fish with a streamlined body and distinctive yellow or golden stripes along their sides. They are often recognized by their shiny, silvery appearance.Yellow scad primarily feed on zooplankton, small fish, and crustaceans.Yellow scad generally spawn in open waters, releasing eggs into the water column where fertilization occurs externally.",
      },
      {
        categoryName: "seaFish",
        discount: "-25%",
        featured: false,
        img: seabass,
        stock: "In Stock",
        productName: "Sea bass fish",
        mainAmount: 300.0,
        subAmount: 450.0,
        type: kilo,
        para: "Sea bass have elongated bodies, a large mouth, and a streamlined shape, making them powerful swimmers.  Sea bass are predatory fish that primarily feed on smaller fish, crustaceans, and mollusks.Sea bass typically spawn in warmer months. For example, striped bass migrate to freshwater rivers to spawn.",
      },
    ],
    freshWater: [
      {
        categoryName: "freshWater",
        discount: "-34%",
        featured: false,
        img: barramundi,
        stock: "In Stock",
        productName: "Barramundi fish",
        mainAmount: 250.0,
        subAmount: 350.0,
        type: kilo,
        para: "Barramundi can be recognized by a distinct pointed head, concave forehead, large jaw extending behind the eye and a rounded tail ﬁn.They have a dorsal ﬁn with seven or eight strong spines and a second dorsal in often or eleven rays.",
      },
      {
        categoryName: "freshWater",
        discount: "-50%",
        featured: false,
        img: catla,
        stock: "In Stock",
        productName: "Catla fish",
        mainAmount: 100.0,
        subAmount: 150.0,
        type: kilo,
        para: "Catla is a ﬁsh with large and broad head, a large protruding lower jaw, and upturned mouth. It has large, greyish scales on its dorsal side and whitish on its belly. It reaches up to 182 cm (6.0 ft) in length and 38.6 kg (85 lb) in weight.",
      },
      {
        categoryName: "freshWater",
        discount: "-14%",
        featured: true,
        img: rohu,
        stock: "In Stock",
        productName: "Rohu fish",
        mainAmount: 100.0,
        subAmount: 150.0,
        type: kilo,
        para: "The rohu is a large, silver-colored ﬁsh of typical cyprinid shape, with a conspicuously arched head. Adults can reach a maximum weight of 45 kg (99 lb) and maximum length of 2 m (6.6 ft), but average around 1⁄2 m (1.6 ft).",
      },
      {
        categoryName: "freshWater",
        discount: "-25%",
        featured: false,
        img: tilapia,
        stock: "In Stock",
        productName: "Thilapia fish",
        mainAmount: 100.0,
        subAmount: 150.0,
        type: kilo,
        para: "Thilapia are a hardy, fast growing ﬁsh, that can live up to ten years and reach ten pounds in weight. Thilapia are shaped like a sunﬁsh or crappie and are easily identiﬁable by the interrupted lateral line characteristic of the Chiclid family of ﬁshes.",
      },
      {
        categoryName: "freshWater",
        discount: "-14%",
        featured: true,
        img: pilot,
        stock: "In Stock",
        productName: "Pilot fish",
        mainAmount: 80.0,
        subAmount: 100.0,
        type: kilo,
        para: "The pilot ﬁsh is elongated and has a forked tail, a lengthwise keel on each side of the tail base, and a low ﬁrst dorsal ﬁn. It grows to a length of about 60 cm (2 feet) but is usually about 35 cm long. It is distinctively marked with ﬁve to seven vertical, dark bands on a bluish body.",
      },
      {
        categoryName: "freshWater",
        discount: "-14%",
        featured: true,
        img: eel,
        stock: "In Stock",
        productName: "Eel fish",
        mainAmount: 150.0,
        subAmount: 250.0,
        type: kilo,
        para: "An eel is distinguished externally from most other ﬁshes by its elongated body, which is seldom laterally compressed. A continuous dorsal, anal, and caudal ﬁn runs around the tail tip pelvic ﬁns are always absent; and gill openings are usually reduced.",
      },
      {
        categoryName: "freshWater",
        discount: "-14%",
        featured: true,
        img: whiteCarpFish,
        stock: "In Stock",
        productName: "White Carp fish",
        mainAmount: 100.0,
        subAmount: 150.0,
        type: kilo,
        para: "The mrigal carp (Cirrhinus cirrhosus), also known as the white carp, is a species of ray-ﬁnned ﬁsh in the carp family. Native to streams and rivers in India, the only surviving wild population is in the Cauvery River, leading to its IUCN rating as vulnerable.",
      },
      {
        categoryName: "freshWater",
        discount: "-14%",
        featured: true,
        img: catFish,
        stock: "In Stock",
        productName: "Cat fish",
        mainAmount: 100.0,
        subAmount: 150.0,
        type: kilo,
        para: "Catﬁsh are so-named because of their whisker- like barbels, which are located on the nose, each side of the mouth, and on the chin. Most catﬁsh possess leading spines in their dorsal and pectoral ﬁns. Catﬁsh are scaleless, a characteristic of from most other teleost ﬁsh.",
      },
      {
        categoryName: "freshWater",
        discount: "-14%",
        featured: true,
        img: calms,
        stock: "In Stock",
        productName: "Clams",
        mainAmount: 250.0,
        subAmount: 350.0,
        type: kilo,
        para: " Clams are filter feeders, meaning they extract food particles from the water. They primarily consume phytoplankton, bacteria, and organic debris. Clam larvae develop into juvenile clams and eventually settle into their adult habitats, where they grow to maturity.Clams are a staple in various cuisines around the world, enjoyed for their sweet and briny flavor.",
      },
      {
        categoryName: "freshWater",
        discount: "-14%",
        featured: true,
        img: codfish,
        stock: "In Stock",
        productName: "Cod fish",
        mainAmount: 100.0,
        subAmount: 150.0,
        type: kilo,
        para: "Cod fish are characterized by their elongated bodies, large heads, and three dorsal fins.  Cod are predatory fish that primarily feed on smaller fish, crustaceans, and mollusks.Cod typically spawn in colder months, releasing eggs into the water where fertilization occurs externally. Cod is highly valued for its mild flavor and flaky texture, making it a favorite in many cuisines.",
      },
      {
        categoryName: "freshWater",
        discount: "-14%",
        featured: true,
        img: murrelfish,
        stock: "In Stock",
        productName: "Murrel fish",
        mainAmount: 300.0,
        subAmount: 500.0,
        type: kilo,
        para: " Murrel fish are an important species for local fisheries, contributing to the livelihoods of many fishing communities. Increasing interest in farming murrel fish sustainably is seen as a way to meet growing demand without overfishing wild populations.Murrel fish typically spawn during the monsoon season, laying eggs in shallow waters among vegetation.",
      },
      {
        categoryName: "freshWater",
        discount: "-14%",
        featured: true,
        img: goldenfish,
        stock: "In Stock",
        productName: "Golden fish",
        mainAmount: 150.0,
        subAmount: 250.0,
        type: kilo,
        para: "Goldfish are known for their bright orange-gold color, round bodies, and long, flowing fins.Goldfish are omnivores and enjoy a varied diet, including fish flakes, pellets, vegetables, and live or frozen foods like brine shrimp.Golden orfe primarily feed on insects, small invertebrates, and plant material in their natural habitats.",
      },
      {
        categoryName: "freshWater",
        discount: "-14%",
        featured: true,
        img: riverfish,
        stock: "In Stock",
        productName: "River fish",
        mainAmount: 150.0,
        subAmount: 250.0,
        type: kilo,
        para: "River eels have long, snake-like bodies with smooth, slimy skin and a dorsal fin that runs the length of their body. River eels are opportunistic feeders, primarily consuming small fish, crustaceans, and insects. River eels are catadromous, meaning they migrate from freshwater to the ocean to spawn, typically in the Sargasso Sea.",
      },
    ],
    prawn: [
      {
        categoryName: "prawn",
        discount: "-34%",
        featured: false,
        img: atlantic,
        stock: "In Stock",
        productName: "Atlantic prawn",
        mainAmount: 300.0,
        subAmount: 400.0,
        type: kilo,
        para: "The Atlantic white shrimp was the first commercially important shrimp in the United States. The prawn species' body is bluish-white in color with slightly pink sides and has tail flippers that are black near the base with green and yellow margins.",
      },
      {
        categoryName: "prawn",
        discount: "-50%",
        featured: false,
        img: blackTigerShrimp,
        stock: "In Stock",
        productName: "Black Tiger Shrimp",
        mainAmount: 350.0,
        subAmount: 450.0,
        type: kilo,
        para: "The Black Tiger Shrimp's natural range is vast, from the east coast of Africa, throughout the Indian Ocean, and in South Asia. Black Tiger Shrimp are named for their large size and striped tails and can reach a length of over a foot in some cases.",
      },
      {
        categoryName: "prawn",
        discount: "-14%",
        featured: true,
        img: northernPrawn,
        stock: "In Stock",
        productName: "Northern Prawn",
        mainAmount: 200.0,
        subAmount: 250.0,
        type: kilo,
        para: "The Northern prawn is a hermaphrodite and starts its life as a male after 4-5 years it turns female. The species is found mostly in areas with soft, muddy sediment where temperatures range from 1-6°C at a depth between 150 and 600 meters.",
      },
      {
        categoryName: "prawn",
        discount: "-25%",
        featured: false,
        img: whiteLegShrimp,
        stock: "In Stock",
        productName: "White Leg Shrimp",
        mainAmount: 150.0,
        subAmount: 250.0,
        type: kilo,
        para: "L. vannamei grows to a maximum length of 230 mm (9.1 in),with a carapace length of 90 mm (3.5 in). Adults live in the ocean, at depths to 72 m (236 ft), while juveniles live in estuaries.The rostrum is moderately long, with 7–10 teeth on the dorsal side and two to four teeth on the ventral side.",
      },
      {
        categoryName: "prawn",
        discount: "-33%",
        featured: false,
        img: giantTigerShrimp,
        stock: "In Stock",
        productName: "Giant Tiger Shrimp",
        mainAmount: 400.0,
        subAmount: 500.0,
        type: kilo,
        para: "Females can reach about 33 cm (13 in) long, but are typically 25–30 cm (10–12 in) long and weigh 200–320 g (7–11 oz); males are slightly smaller at 20–25 cm (8–10 in) long and weighing 100–170 g (3.5–6.0 oz). The carapace and abdomen are transversely banded with alternative red and white.",
      },
      {
        categoryName: "prawn",
        discount: "-33%",
        featured: false,
        img: spotPrawn,
        stock: "In Stock",
        productName: "Spot prawn",
        mainAmount: 300.0,
        subAmount: 350.0,
        type: kilo,
        para: "Spot prawns are large shrimp known for their sweet, tender meat. Spot prawns are opportunistic feeders, consuming a varied diet that includes phytoplankton, zooplankton, small fish, and detritus.They use their long antennae to detect food in their environment, foraging primarily at night. Spot prawns typically spawn in late winter to early spring. Females carry fertilized eggs under their tails until they hatch.",
      },
    ],
    crab: [
      {
        categoryName: "crab",
        discount: "-14%",
        featured: true,
        img: dungness,
        stock: "In Stock",
        productName: "Dungness Crab",
        mainAmount: 100.0,
        subAmount: 150.0,
        type: kilo,
        para: "The Dungeness crab is a decapod, related to shrimp, lobster, and other crab. It has a broad, oval body covered by a hard chitinous shell, four pairs of walking legs and a pair of claws.",
      },
      {
        categoryName: "crab",
        discount: "-14%",
        featured: true,
        img: mud,
        stock: "In Stock",
        productName: "Mud Crab",
        mainAmount: 120.0,
        subAmount: 170.0,
        type: kilo,
        para: "Mud Crab are a member of the family Portunidae. They are large crabs with a smooth, broad carapace and possess sizeable claws used for crushing and cutting prey. Mud Crab are generally very dark brown to mottled green in colour.",
      },
      {
        categoryName: "crab",
        discount: "-25%",
        featured: false,
        img: blueCrab,
        stock: "In Stock",
        productName: "Blue Crab",
        mainAmount: 250.0,
        subAmount: 350.0,
        type: kilo,
        para: "The blue crab's shell—called the “carapace”—is a blue to olive green. Shells can reach up to 9 inches across. Blue crab claws are bright blue, and mature females have red tips on their claws too. They have three pairs of walking legs and rear swimming legs that look like paddles.",
      },
      {
        categoryName: "crab",
        discount: "-33%",
        featured: false,
        img: seaCrab,
        stock: "In Stock",
        productName: "Sea Crab",
        mainAmount: 120.0,
        subAmount: 170.0,
        type: kilo,
        para: "Sea crabs are crustaceans that don't have a backbone and are protected by a hard shell. Their pincers are used catch food and to ﬁght off predators. The thousands of sea crab species can be found in oceans all around the world.",
      },
      {
        categoryName: "crab",
        discount: "-14%",
        featured: true,
        img: stoneCrab,
        stock: "In Stock",
        productName: "Stone Crab",
        mainAmount: 120.0,
        subAmount: 200.0,
        type: kilo,
        para: "Stone Crab can be found in both shallow, nearshore waters as well as deep, offshore benthic environments. Their preferred habitats include sandy/muddy bottoms, rocky outcrops, and seagrass beds.",
      },
    ],
    squid: [
      {
        categoryName: "squid",
        discount: "-50%",
        featured: false,
        img: european,
        stock: "In Stock",
        productName: "European squid",
        mainAmount: 150.0,
        subAmount: 200.0,
        type: kilo,
        para: "The European squid has a long, moderately slender and cylindrical body. Rhomboid ﬁns comprise two-thirds of the mantle length, though locomotion is via jet propulsion. The posterior border is slightly concave.",
      },
      {
        categoryName: "squid",
        discount: "-50%",
        featured: false,
        img: giant,
        stock: "In Stock",
        productName: "Giant squid",
        mainAmount: 200.0,
        subAmount: 300.0,
        type: kilo,
        para: "The giant squid is morphologically similar to smaller squid species, possessing a head, a mantle, and other features associated with cephalopods. It is characterized by the presence of two large ﬁns attached to its mantle, eight arms, and two long tentacles.",
      },
      {
        categoryName: "squid",
        discount: "-50%",
        featured: false,
        img: japaneseFlying,
        stock: "In Stock",
        productName: "Japanese Flying squid",
        mainAmount: 170.0,
        subAmount: 200.0,
        type: kilo,
        para: "The squid has eight arms and two tentacles with suction cups along the underside. In between the arms sits the beak, which housesa toothed, tongue-like appendage called the radula.",
      },
      {
        categoryName: "squid",
        discount: "-34%",
        featured: false,
        img: arrow,
        stock: "In Stock",
        productName: "Arrow squid",
        mainAmount: 110.0,
        subAmount: 150.0,
        type: kilo,
        para: "Both species of Arrow Squid have smooth cylindrical bodies with short pointed ﬁns. Their colour is white with tones of bronze on the mantle and head. The eyes are black. The ﬂesh is ﬁrm and ivory coloured, covered by a speckled membrane.",
      },
      {
        categoryName: "squid",
        discount: "-50%",
        featured: false,
        img: jumbo,
        stock: "In Stock",
        productName: "Jumbo squid",
        mainAmount: 300.0,
        subAmount: 400.0,
        type: kilo,
        para: "The jumbo squid (Dosidicus gigas) is the most abundant cephalopod species in the Southeast Paciﬁc Ocean, ranging from the North American coast to southern Chile. This elusive creature reaches up to 3 meters in length and weighs up to 50 kilograms.",
      },
    ],
    pickles_frozenItems: [
      {
        categoryName: "pickles_frozenItems",
        discount: "-34%",
        featured: false,
        img: fishPickle,
        stock: "In Stock",
        productName: `Fish Pickle ${gram100}`,
        mainAmount: 104.0,
        subAmount: 120.0,
        para: "Fish pickle is a delicious and tangy condiment made by marinating pieces of fish in a spicy mixture of vinegar, spices, and oil. This traditional delicacy, popular in many coastal regions, combines the rich flavors of fresh fish with the aromatic intensity of Indian spices.",
      },
      {
        categoryName: "pickles_frozenItems",
        discount: "-34%",
        featured: false,
        img: prawnpickle,
        stock: "In Stock",
        productName: `Prawn Pickle ${gram100}`,
        mainAmount: 50.0,
        subAmount: 65.0,
        para: "Prawn pickle is a tangy and spicy condiment made by marinating prawns in a flavorful mixture of vinegar, spices, and oil. This traditional delicacy is especially popular in coastal regions and combines the rich flavors of fresh prawns with aromatic Indian spices.",
      },
      {
        categoryName: "pickles_frozenItems",
        discount: "-34%",
        featured: false,
        img: fishKebabMasala,
        stock: "In Stock",
        productName: `Fish Kebab Masala ${gram}`,
        mainAmount: 29.0,
        subAmount: 58.0,
        para: "Fish Kebab Masala is a versatile and aromatic spice blend specifically crafted to elevate the flavors of fish kebabs. This masala mix combines a rich array of spices and herbs, offering a harmonious balance of heat, tang, and savory notes that infuse your fish kebabs with an irresistible taste.",
      },
      {
        categoryName: "pickles_frozenItems",
        discount: "-34%",
        featured: false,
        img: fishKulambu,
        stock: "In Stock",
        productName: `Fish Kulambu Masala ${gram100}`,
        mainAmount: 30.0,
        subAmount: 40.0,
        para: "Fish Kolambu Masala is a robust and aromatic spice blend specifically crafted to enhance the flavors of traditional South Indian fish curries. This masala mix combines an array of spices and herbs, providing a perfect balance of heat, tang, and aromatic depth that infuses your fish kolambu with an authentic and delectable taste.",
      },
      {
        categoryName: "pickles_frozenItems",
        discount: "-34%",
        featured: false,
        img: fish_65,
        stock: "In Stock",
        productName: `Fish 65 Masala ${gram}`,
        mainAmount: 59.0,
        subAmount: 100.0,
        para: "Fish 65 Masala is a specially crafted spice blend designed to create the iconic Fish 65, a popular South Indian appetizer. This masala mix combines a medley of spices and herbs, delivering a perfect balance of heat, tanginess, and savory notes that infuse your fish with a delectable and crispy coating.",
      },
    ],
    driedSeaFood: [
      {
        categoryName: "driedSeaFood",
        discount: "-34%",
        featured: false,
        img: driedAnchovy,
        stock: "In Stock",
        productName: "Dried Anchovies",
        mainAmount: 500.0,
        subAmount: 700.0,
        type: kilo,
        para: "These small, silvery ﬁsh are typically sun-dried or sometimes oven-dried until they become hard and brittle. Drying anchovies not only extends their shelf life but also intensiﬁes their unique umami ﬂavor.",
      },
      {
        categoryName: "driedSeaFood",
        discount: "-50%",
        featured: false,
        img: driedShankara,
        stock: "In Stock",
        productName: "Dried Shankara fish",
        mainAmount: 400.0,
        subAmount: 700.0,
        type: kilo,
        para: "Sankara ﬁsh, also known as Red snapper in English, is a top seafood favourite. Sankara is a popular ﬁsh with a ﬁrm texture, a distinctly sweet ﬂavour, and an imprssive nutritional proﬁle.",
      },
      {
        categoryName: "driedSeaFood",
        discount: "-14%",
        featured: true,
        img: driedShrimp,
        stock: "In Stock",
        productName: "Dried Baby shrimp",
        mainAmount: 450.0,
        subAmount: 800.0,
        type: kilo,
        para: "Dried shrimp are shrimp that have been sun-dried and shrunk to a thumbnail size. They are used in many East Asian, Southeast Asian and South Asian cuisines, imparting a unique umami taste.",
      },
      {
        categoryName: "driedSeaFood",
        discount: "-25%",
        featured: false,
        img: driedShell,
        stock: "In Stock",
        productName: "Dried Shell",
        mainAmount: 600.0,
        subAmount: 900.0,
        type: kilo,
        para: "The shell itself is the exoskeleton of a soft-bodied mollusk. Composed mainly of calcium carbonate, the shell is secreted by the animal and provides protection as well as support for various organs",
      },
      {
        categoryName: "driedSeaFood",
        discount: "-33%",
        featured: false,
        img: driedCroker,
        stock: "In Stock",
        productName: "Dried croaker fish",
        mainAmount: 400.0,
        subAmount: 600.0,
        type: kilo,
        para: "Dried Croaker Fish contains many essential nutrients, such as omega-3 fatty acids and high quality proteins. Croaker ﬁsh can be processed into many delicious dishes, but usually be dried because it has a strong taste, fast processing and convenient to use.",
      },
      {
        categoryName: "driedSeaFood",
        discount: "-14%",
        featured: true,
        img: driedMackrelFish,
        stock: "In Stock",
        productName: "Dried Mackrel Fish",
        mainAmount: 300.0,
        subAmount: 500.0,
        type: kilo,
        para: "Dry Mackerel Fish is salt-cured and dried mackerel ﬁsh. It is either sun-dried or dried in a food dehydrator until it is completely dry.",
      },
      {
        categoryName: "driedSeaFood",
        discount: "-33%",
        featured: false,
        img: driedPrawn,
        stock: "In Stock",
        productName: "Dried Prawn",
        mainAmount: 500.0,
        subAmount: 600.0,
        type: kilo,
        para: "Tasty Nibbles Dried Prawns are wild caught Prawns that have been hygienically dried and shrunk to a thumbnail size. They are used in many Indian, East Asian, Southeast Asian, and South Asian cuisines, unique umami taste.",
      },
      {
        categoryName: "driedSeaFood",
        discount: "-14%",
        featured: true,
        img: driedEel,
        stock: "In Stock",
        productName: "Dried eel fish",
        mainAmount: 300.0,
        subAmount: 500.0,
        type: kilo,
        para: "Eels are catadromous, meaning they primarily live in rivers and estuaries, but migrate out to the ocean - the Sargasso Sea, to spawn. American eels are an elongated ﬁsh, with fairly small ﬁns.",
      },
      {
        categoryName: "driedSeaFood",
        discount: "-14%",
        featured: true,
        img: driedBombayDuckFish,
        stock: "In Stock",
        productName: "Dried bombay duck fish",
        mainAmount: 300.0,
        subAmount: 500.0,
        type: kilo,
        para: "Bombay duck ﬁsh of the family Synodontidae, found in estuaries of northern India, where it is widely used as a food ﬁsh and, when dried,or brown in colour with small, dark speckles.",
      },
      {
        categoryName: "driedSeaFood",
        discount: "-14%",
        featured: true,
        img: driedSardine,
        stock: "In Stock",
        productName: "Dried sardine fish",
        mainAmount: 200.0,
        subAmount: 500.0,
        type: kilo,
        para: "Dried sardines are a popular ingredient in many cuisines, known for their strong flavor and nutritional benefits.Dried sardines are high in protein, making them an excellent source for muscle building and repair.",
      },
      {
        categoryName: "driedSeaFood",
        discount: "-14%",
        featured: true,
        img: driedRibbon,
        stock: "In Stock",
        productName: "Dried ribbon fish",
        mainAmount: 200.0,
        subAmount: 400.0,
        type: kilo,
        para: "Dried ribbon fish, often referred to as dried hairtail or dried ribbonfish, is a popular ingredient in various Asian cuisines.Rich in protein, making it a great option for muscle maintenance and growth.Contains beneficial omega-3s, promoting heart health. ",
      },
      {
        categoryName: "driedSeaFood",
        discount: "-14%",
        featured: true,
        img: driedPorgy,
        stock: "In Stock",
        productName: "Dried porgy fish",
        mainAmount: 200.0,
        subAmount: 400.0,
        type: kilo,
        para: "Dried porgy fish, often simply referred to as dried porgy, is a popular ingredient in various culinary traditions, especially in Mediterranean and Asian cuisines. Dried porgy is high in protein, making it a great source of essential amino acids.",
      },
    ],
  };

  const mapProductValue = productList[propsProduct];

  // Convert to modal content

  const [moveContent, setMoveContent] = useState([]);

  return (
    <div>
      <div className="product_div_overall" style={{ paddingBottom: "30px" }}>
        {mapProductValue.map((product) => (
          <div className="product_div">
            <div className="product_discount_div">
              <div>
                <span className="product_dicount">{product.discount}</span>
                <span
                  className={
                    product.featured === true
                      ? "product_featured"
                      : "product_featured_none"
                  }
                >
                  Featured
                </span>
              </div>
              <div className="product_cart_div">
                <div>
                  <button className="product_heart" custom_tooltip="Upcoming">
                    <i className="fa-regular fa-heart"></i>
                  </button>
                </div>
                <div>
                  <button
                    className="product_eye"
                    custom_tooltip="Quick View"
                    onClick={() => {
                      setMoveContent(product);
                    }}
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalAllProduct"
                  >
                    <i className="fa-regular fa-eye"></i>
                  </button>
                </div>
              </div>
              <div
                className="modal fade"
                id="exampleModalAllProduct"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered modal-xl">
                  <div className="modal-content" id="product_modal_width">
                    <div className="modal-body" id="product_modal_content_overall">
                      <div className="productTwo_closeMob">
                        <button
                          id="productTwo_close"
                          type="button"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <i className="fa-solid fa-xmark fa-lg"></i>
                        </button>
                      </div>
                      <div className="product_modal_one">
                        <img src={moveContent.img} alt="product_img"></img>
                      </div>
                      <div className="product_modal_two">
                        <div className="productTwo_title">
                          <div>
                            <h4 className="productTwo_title_size">
                              {moveContent.productName}
                            </h4>
                          </div>
                          <div className="productTwo_closeDesktop">
                            <button
                              id="productTwo_close"
                              type="button"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              <i className="fa-solid fa-xmark fa-lg"></i>
                            </button>
                          </div>
                        </div>
                        <div>
                          <div className="productTwo_rateDiv">
                            <div className="product_rate">
                              <span className="productTwo_main_rate">
                                ₹ {moveContent.mainAmount} {moveContent.type}
                              </span>
                              <span className="productTwo_sub_rate">
                                ₹{moveContent.subAmount}
                              </span>
                            </div>
                            <div className="productTwo_stock_gap">
                              <span
                                className={
                                  moveContent.stock === "In Stock"
                                    ? "productStock_update"
                                    : "productOutOf_stock_update"
                                }
                              >
                                <span id="productTwo_dot">•</span>{" "}
                                {moveContent.stock === "In Stock"
                                  ? "In Stock"
                                  : "Out Of Stock"}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="productTwo_contentDiv">
                          <p className="productTwo_para">{moveContent.para}</p>
                        </div>
                        <div className="productTwo_BuyNow">
                          <button
                            className="productTwo_enquiryBtn"
                            onClick={handleChatSubmit}
                          >
                            <i
                              className="fa-solid fa-phone"
                              style={{ color: "#ffffff", fontSize: "13.5px" }}
                            ></i>
                            <span style={{ marginLeft: "6px" }}>Buy Now</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="product_img_div">
                <img className="product_img" src={product.img} alt="demo" />
              </div>
            </div>
            <div>
              <div>
                <ul
                  className={
                    product.stock === "In Stock"
                      ? "stock_update"
                      : "outOf_stock_update"
                  }
                >
                  <li>
                    {product.stock === "In Stock" ? "In Stock" : "Out Of Stock"}
                  </li>
                </ul>
              </div>
              <div>
                <Link className="product_name_link">{product.productName}</Link>
              </div>
              <div className="product_rate">
                <span className="product_main_rate">
                  ₹ {product.mainAmount} {product.type}
                </span>
                <span className="product_sub_rate">₹{product.subAmount}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
