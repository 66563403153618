import { useEffect } from "react"
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom"
import { setCategorySearchValue, setCategorySearchValueName, setMobileCategoryValue } from "../../features/slice/searchProductSlice";

export const MainFunctionRender = ({ children }) => {

    const location = useLocation();
    const dispatch = useDispatch();
 
    useEffect(() => {
        if (location.pathname !== "/shop") {
            dispatch(setCategorySearchValue(""));
            dispatch(setCategorySearchValueName(""));
            dispatch(setMobileCategoryValue(""));
        }
    }, [location, dispatch])

    return (
        <div>
            {children}
        </div>
    )
}
