import React from "react";
import { useEffect, useState } from "react";

// CSS Style 
import "../../components/styles/user/Scroll_to_Top_Section.css";

export const ScrolltoTopSection = () => {
  const [scrollOpen, setScrollOpen] = useState(false);

  useEffect(() => {
    const reHeight = () => {
      if (window.scrollY >= 100) {
        setScrollOpen(true);
      } else {
        setScrollOpen(false);
      }
    };

    reHeight();

    window.addEventListener("scroll", reHeight);

    return () => {
      window.removeEventListener("scroll", reHeight);
    };
  }, []);

  // click to scroll 
  const handleScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      {scrollOpen === true ? (
        <section className="scroll_btn_overall">
          <div>
            <button className="scroll_btn" onClick={handleScroll}>
              <i id="scroll_btn_icon" className="fa-solid fa-chevron-up fa-lg"></i>
            </button>
          </div>
        </section>
      ) : (
        ""
      )}
    </div>
  );
};
